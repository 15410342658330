import React from 'react'
import { UserLoggedIn, CollaboratorCommitteeCreateInput, CollaboratorUpdateInput, CollaboratorCommitteeDeleteInput } from '../../ApiModels'

import '../Admin.css'
import RequestModule from '../RequestModule';
import { collaboratorCreate, collaboratorCreateCommittee, collaboratorRead, collaboratorReadById, collaboratorUpdate, collaboratorUpdateAddress, collaboratorDelete, collaboratorDeleteCommittee } from '../../Requests';
import { Create, RequestMethod, Update, Delete } from '../../Global';
import { toNumber } from '../../Helper';

interface CollaboratorProps {
    user: UserLoggedIn
}

interface CollaboratorState {
}

export default class Collaborator extends React.Component<CollaboratorProps, CollaboratorState> {
    constructor(props: CollaboratorProps) {
        super(props)
        this.state = {
        }
    }

    async componentDidMount() {

    }

    public render() {
        return <div>
            {/* Create */}
            <RequestModule<CollaboratorCreateInput>
                user={this.props.user}
                title="Create Collaborator"
                path="/collaborator/create"
                requestMethod={RequestMethod.POST}
                state={{
                    name: "",
                    description: "",
                    imgUrl: "",
                    link: "",
                    streetName: "",
                    houseNumber: 0,
                    houseNumberLetter: null,
                    houseNumberAddition: null,
                    postalCode: "",
                    city: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[Create.COLLABORATOR]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "name",
                        type: "text",
                        getter: (thiz: RequestModule<CollaboratorCreateInput>) => thiz.state.name,
                        setter: (v: any, thiz: RequestModule<CollaboratorCreateInput>) => thiz.setState({ name: v })
                    },
                    {
                        key: "description",
                        type: "text",
                        getter: (thiz: RequestModule<CollaboratorCreateInput>) => thiz.state.description,
                        setter: (v: any, thiz: RequestModule<CollaboratorCreateInput>) => thiz.setState({ description: v })
                    },
                    {
                        key: "imgUrl",
                        type: "text",
                        getter: (thiz: RequestModule<CollaboratorCreateInput>) => thiz.state.imgUrl,
                        setter: (v: any, thiz: RequestModule<CollaboratorCreateInput>) => thiz.setState({ imgUrl: v })
                    },
                    {
                        key: "link",
                        type: "text",
                        getter: (thiz: RequestModule<CollaboratorCreateInput>) => thiz.state.link,
                        setter: (v: any, thiz: RequestModule<CollaboratorCreateInput>) => thiz.setState({ link: v })
                    },
                    {
                        key: "streetName",
                        type: "text",
                        getter: (thiz: RequestModule<CollaboratorCreateInput>) => thiz.state.streetName,
                        setter: (v: any, thiz: RequestModule<CollaboratorCreateInput>) => thiz.setState({ streetName: v })
                    },
                    {
                        key: "houseNumber",
                        type: "number",
                        getter: (thiz: RequestModule<CollaboratorCreateInput>) => thiz.state.houseNumber,
                        setter: (v: any, thiz: RequestModule<CollaboratorCreateInput>) => thiz.setState({ houseNumber: toNumber(parseInt(v)) })
                    },
                    {
                        key: "houseNumberLetter",
                        type: "text",
                        getter: (thiz: RequestModule<CollaboratorCreateInput>) => thiz.state.houseNumberLetter,
                        setter: (v: string, thiz: RequestModule<CollaboratorCreateInput>) => thiz.setState({ houseNumberLetter: (v.replace(" ", "").length > 0) ? v : null })
                    },
                    {
                        key: "houseNumberAddition",
                        type: "text",
                        getter: (thiz: RequestModule<CollaboratorCreateInput>) => thiz.state.houseNumberAddition,
                        setter: (v: string, thiz: RequestModule<CollaboratorCreateInput>) => thiz.setState({ houseNumberAddition: (v.replace(" ", "").length > 0) ? v : null })
                    },
                    {
                        key: "postalCode",
                        type: "text",
                        getter: (thiz: RequestModule<CollaboratorCreateInput>) => thiz.state.postalCode,
                        setter: (v: string, thiz: RequestModule<CollaboratorCreateInput>) => thiz.setState({ postalCode: v })
                    },
                    {
                        key: "city",
                        type: "text",
                        getter: (thiz: RequestModule<CollaboratorCreateInput>) => thiz.state.city,
                        setter: (v: string, thiz: RequestModule<CollaboratorCreateInput>) => thiz.setState({ city: v })
                    }
                ]}
                submit={(state) => collaboratorCreate({
                    name: state.name,
                    description: state.description,
                    imgUrl: state.imgUrl,
                    link: state.link,
                    address: {
                        streetName: state.streetName,
                        houseNumber: state.houseNumber,
                        houseNumberLetter: state.houseNumberLetter,
                        houseNumberAddition: state.houseNumberAddition,
                        postalCode: state.postalCode,
                        city: state.city
                    },
                    isActive: true
                })}
            />

            <RequestModule<CollaboratorCommitteeCreateInput>
                user={this.props.user}
                title="Associate Collaborator with Committee"
                path="/collaborator/create/committee"
                requestMethod={RequestMethod.POST}
                state={{
                    id: "",
                    committeeId: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[Create.COLLABORATOR_COMMITTEE]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<CollaboratorCommitteeCreateInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<CollaboratorCommitteeCreateInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "committeeId",
                        type: "text",
                        getter: (thiz: RequestModule<CollaboratorCommitteeCreateInput>) => thiz.state.committeeId,
                        setter: (v: any, thiz: RequestModule<CollaboratorCommitteeCreateInput>) => thiz.setState({ committeeId: v })
                    }
                ]}
                submit={(state) => collaboratorCreateCommittee(state)}
            />

            {/* Read */}
            <RequestModule<{}>
                user={this.props.user}
                title="Read all Collaborators"
                path="/collaborator/read"
                requestMethod={RequestMethod.GET}
                state={{}}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[]}
                submit={(state) => collaboratorRead()}
            />

            <RequestModule<Input>
                user={this.props.user}
                title="Read Collaborator by id"
                path="/collaborator/read/{id}"
                requestMethod={RequestMethod.GET}
                state={{
                    id: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<Input>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<Input>) => thiz.setState({ id: v })
                    }
                ]}
                submit={(state) => collaboratorReadById(state.id)}
            />

            <RequestModule<CollaboratorUpdateInput>
                user={this.props.user}
                title="Update Collaborator"
                path="/collaborator/update"
                requestMethod={RequestMethod.PUT}
                state={{
                    id: "",
                    name: "",
                    description: "",
                    imgUrl: "",
                    link: "",
                    isActive: true
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[Update.COLLABORATOR]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<CollaboratorUpdateInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<CollaboratorUpdateInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "name",
                        type: "text",
                        getter: (thiz: RequestModule<CollaboratorUpdateInput>) => thiz.state.name,
                        setter: (v: any, thiz: RequestModule<CollaboratorUpdateInput>) => thiz.setState({ name: v })
                    },
                    {
                        key: "description",
                        type: "text",
                        getter: (thiz: RequestModule<CollaboratorUpdateInput>) => thiz.state.description,
                        setter: (v: any, thiz: RequestModule<CollaboratorUpdateInput>) => thiz.setState({ description: v })
                    },
                    {
                        key: "imgUrl",
                        type: "text",
                        getter: (thiz: RequestModule<CollaboratorUpdateInput>) => thiz.state.imgUrl,
                        setter: (v: any, thiz: RequestModule<CollaboratorUpdateInput>) => thiz.setState({ imgUrl: v })
                    },
                    {
                        key: "link",
                        type: "text",
                        getter: (thiz: RequestModule<CollaboratorUpdateInput>) => thiz.state.link,
                        setter: (v: any, thiz: RequestModule<CollaboratorUpdateInput>) => thiz.setState({ link: v })
                    },
                    {
                        key: "isActive",
                        type: "text",
                        getter: (thiz: RequestModule<CollaboratorUpdateInput>) => thiz.state.isActive,
                        setter: (v: any, thiz: RequestModule<CollaboratorUpdateInput>) => thiz.setState({ isActive: toNumber(parseInt(v)) === 1 ? true : false })
                    }
                ]}
                submit={(state) => collaboratorUpdate(state)}
            />

            <RequestModule<CollaboratorAddressUpdateInput>
                user={this.props.user}
                title="Update Collaborator Address"
                path="/collaborator/update/address"
                requestMethod={RequestMethod.PUT}
                state={{
                    id: "",
                    streetName: "",
                    houseNumber: 0,
                    houseNumberLetter: null,
                    houseNumberAddition: null,
                    postalCode: "",
                    city: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[Update.COLLABORATOR]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<CollaboratorAddressUpdateInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<CollaboratorAddressUpdateInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "streetName",
                        type: "text",
                        getter: (thiz: RequestModule<CollaboratorAddressUpdateInput>) => thiz.state.streetName,
                        setter: (v: any, thiz: RequestModule<CollaboratorAddressUpdateInput>) => thiz.setState({ streetName: v })
                    },
                    {
                        key: "houseNumber",
                        type: "number",
                        getter: (thiz: RequestModule<CollaboratorAddressUpdateInput>) => thiz.state.houseNumber,
                        setter: (v: any, thiz: RequestModule<CollaboratorAddressUpdateInput>) => thiz.setState({ houseNumber: toNumber(parseInt(v)) })
                    },
                    {
                        key: "houseNumberLetter",
                        type: "text",
                        getter: (thiz: RequestModule<CollaboratorAddressUpdateInput>) => thiz.state.houseNumberLetter,
                        setter: (v: string, thiz: RequestModule<CollaboratorAddressUpdateInput>) => thiz.setState({ houseNumberLetter: (v.replace(" ", "").length > 0) ? v : null })
                    },
                    {
                        key: "houseNumberAddition",
                        type: "text",
                        getter: (thiz: RequestModule<CollaboratorAddressUpdateInput>) => thiz.state.houseNumberAddition,
                        setter: (v: string, thiz: RequestModule<CollaboratorAddressUpdateInput>) => thiz.setState({ houseNumberAddition: (v.replace(" ", "").length > 0) ? v : null })
                    },
                    {
                        key: "postalCode",
                        type: "text",
                        getter: (thiz: RequestModule<CollaboratorAddressUpdateInput>) => thiz.state.postalCode,
                        setter: (v: string, thiz: RequestModule<CollaboratorAddressUpdateInput>) => thiz.setState({ postalCode: v })
                    },
                    {
                        key: "city",
                        type: "text",
                        getter: (thiz: RequestModule<CollaboratorAddressUpdateInput>) => thiz.state.city,
                        setter: (v: string, thiz: RequestModule<CollaboratorAddressUpdateInput>) => thiz.setState({ city: v })
                    }
                ]}
                submit={(state) => collaboratorUpdateAddress({
                    id: state.id,
                    address: {
                        streetName: state.streetName,
                        houseNumber: state.houseNumber,
                        houseNumberLetter: state.houseNumberLetter,
                        houseNumberAddition: state.houseNumberAddition,
                        postalCode: state.postalCode,
                        city: state.city
                    }
                })}
            />

            {/* Delete */}
            <RequestModule<Input>
                user={this.props.user}
                title="Delete Collaborator"
                path="/collaborator/delete"
                requestMethod={RequestMethod.DELETE}
                state={{
                    id: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[Delete.COLLABORATOR]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<Input>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<Input>) => thiz.setState({ id: v })
                    }
                ]}
                submit={(state) => collaboratorDelete(state.id)}
            />

            <RequestModule<CollaboratorCommitteeDeleteInput>
                user={this.props.user}
                title="Delete Collaborator association with Committee"
                path="/collaborator/delete/committee"
                requestMethod={RequestMethod.DELETE}
                state={{
                    id: "",
                    committeeId: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[Delete.COLLABORATOR_COMMITTEE]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<CollaboratorCommitteeDeleteInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<CollaboratorCommitteeDeleteInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "committeeId",
                        type: "text",
                        getter: (thiz: RequestModule<CollaboratorCommitteeDeleteInput>) => thiz.state.committeeId,
                        setter: (v: any, thiz: RequestModule<CollaboratorCommitteeDeleteInput>) => thiz.setState({ committeeId: v })
                    }
                ]}
                submit={(state) => collaboratorDeleteCommittee(state)}
            />
        </div>
    }
}

interface Input {
    id: string
}

export interface CollaboratorCreateInput {
    name: string
    description: string
    imgUrl: string
    link: string
    streetName: string
    houseNumber: number
    houseNumberLetter: string | null
    houseNumberAddition: string | null
    postalCode: string
    city: string
}

export interface CollaboratorAddressUpdateInput {
    id: string
    streetName: string
    houseNumber: number
    houseNumberLetter: string | null
    houseNumberAddition: string | null
    postalCode: string
    city: string
}