import { AuthLoginResponse, AuthWhoAmIResponse, Message, UserLoggedIn } from "./ApiModels";
import { authWhoAmIRequest } from "./Requests";
import { isMessage } from "./Helper";

export function saveAuth(
    authLoginResponse: AuthLoginResponse
) {
    localStorage.setItem("memberId", authLoginResponse.memberId)
    localStorage.setItem("token", authLoginResponse.token)
}

function isAuth(): boolean {
    const memberId: string | null = localStorage.getItem("memberId")
    const token: string | null = localStorage.getItem("token")
    return memberId !== null && token !== null
}

export async function checkAuthIsValid(): Promise<UserLoggedIn> {
    if (!isAuth()) {
        return {
            loggedIn: false,
            role: null
        }
    }
    const token: string | null = localStorage.getItem("token")
    if (token !== null) {
        const res: AuthWhoAmIResponse | Message = await authWhoAmIRequest(token)
        const validAuth: boolean = !isMessage(res)
        if (!validAuth) {
            clearAuth()
        }
        const role = !isMessage(res) ? res.role : null
        return {
            loggedIn: validAuth,
            role: role
        }
    } else {
        return {
            loggedIn: false,
            role: null
        }
    }
}

export function getToken(): string | null {
    return localStorage.getItem("token")
}

export function getMemberId(): string | null {
    return localStorage.getItem("memberId")
}

export function clearAuth() {
    localStorage.removeItem("memberId")
    localStorage.removeItem("token")
}