import React from 'react'
import ReactJson from 'react-json-view'
import {UserLoggedIn, EventType} from '../ApiModels'

import './AuthModule.css'
import '../Form.css'
import { RequestMethod, Info, Create, Read, Update, Delete } from '../Global';
import { toUndefinedIfNull } from '../Helper';

export interface Props<T> {
    user: UserLoggedIn
    title: string
    path: string
    requestMethod: RequestMethod
    state: T
    permissionsInfo: Array<Info>
    permissionsCreate: Array<Create>
    permissionsRead: Array<Read>
    permissionsUpdate: Array<Update>
    permissionsDelete: Array<Delete>
    items: Array<Item<T>>
    submit: (state: T) => object | undefined
}

interface Item<T> {
    key: string
    type: string
    options?: EventType[] | undefined
    getter: (thiz: RequestModule<T>) => any
    setter: (v: any, thiz: RequestModule<T>) => void
}

export default class RequestModule<T> extends React.Component<Props<T>, T> {
    constructor(props: Props<T>) {
        super(props)
        this.state = this.props.state
    }

    componentDidMount() {
        if (this.props.user.role !== null) {
            const role = this.props.user.role

            this.authorized = this.props.permissionsInfo.map((item) => role.allowedInfo.includes(item)).every((item) => item) &&
                this.props.permissionsCreate.map((item) => role.allowedCreate.includes(item)).every((item) => item) &&
                this.props.permissionsRead.map((item) => role.allowedRead.includes(item)).every((item) => item) &&
                this.props.permissionsUpdate.map((item) => role.allowedUpdate.includes(item)).every((item) => item) &&
                this.props.permissionsDelete.map((item) => role.allowedDelete.includes(item)).every((item) => item)
            this.forceUpdate()
        }
    }

    authorized: boolean = false
    opened: boolean = false
    sending: boolean = false
    response: object | undefined = undefined

    public render() {
        return !this.authorized
            ? <div className="module">
                <div
                    onClick={() => { }}>
                    <div className="module-header-text">
                        <h4>{this.props.title}</h4>
                        <h5 className="module-header-text-shade">{this.props.requestMethod.toUpperCase()} {this.props.path}</h5>
                    </div>
                </div>
            </div>
            : <div className="module">
                <div
                    className={this.opened ? "module-header module-header-opened " + this.props.requestMethod + "-color-opened" : "module-header " + this.props.requestMethod + "-color"}
                    onClick={() => {
                        this.opened = !this.opened
                        this.forceUpdate()
                    }}>
                    <div className="module-header-text">
                        <h4>{this.props.title}</h4>
                        <h5 className="module-header-text-shade">{this.props.requestMethod.toUpperCase()} {this.props.path}</h5>
                    </div>
                </div>
                {this.opened
                    ? <div className="module-content">
                        <div className="module-content-item">
                            <form
                                className="form"
                                method="post"
                                onSubmit={async event => {
                                    event.preventDefault()
                                    this.sending = true
                                    this.forceUpdate()
                                    this.response = await this.props.submit(this.state)
                                    this.sending = false
                                    this.forceUpdate()
                                }}>
                                {this.props.items.map((item) =>
                                    <div
                                        key={item.key}>
                                        {/* Dropdown */}
                                            {item.type === 'select' ?
                                            <select className="form-input text-box-extra-large"
                                            onChange={event => item.setter(event.target.value, this)}
                                            value={undefined}>
                                                <option value="" disabled selected>Selecteer een optie</option>
                                                { item.options !== undefined ?
                                                    item.options.map((option) =>
                                                        <option value={option.id}>
                                                            {option.name}
                                                        </option>)
                                                : undefined }
                                            </select>
                                            : <input
                                                className="form-input text-box-extra-large"
                                                name={item.key}
                                                placeholder={item.key}
                                                type={item.type}
                                                autoComplete="off"
                                                value={toUndefinedIfNull(item.getter(this))}
                                                onChange={event => item.setter(event.target.value, this)}
                                            />}
                                        {item.key}
                                    </div>
                                )}
                                <div>
                                    <input
                                        className="form-input form-submit-button text-box-extra-large"
                                        type="submit"
                                        value={!this.sending ? "Send" : "Sending..."}
                                        readOnly={this.sending} />
                                    <input
                                        className="form-input form-submit-button text-box-small"
                                        type="button"
                                        value="Clear"
                                        onClick={() => {
                                            this.response = undefined
                                            this.forceUpdate()
                                        }}
                                        readOnly={this.sending} />
                                </div>
                            </form>
                        </div>
                        <div className="module-content-item">
                            {this.response === undefined
                                ? undefined
                                : <div className="module-code">
                                    <h4>Response</h4>
                                    <ReactJson
                                        src={this.response}
                                        name={false}
                                        displayObjectSize={false}
                                        displayDataTypes={false}
                                        collapseStringsAfterLength={128}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                    : undefined
                }
            </div>
    }
}