import {API_URL} from './Global'
import {
    Address,
    AddressCreateInput,
    AddressUpdateInput,
    AuthLoginResponse,
    AuthRoles,
    AuthWhoAmIResponse,
    Collaborator,
    CollaboratorAddressUpdateInput,
    CollaboratorCommitteeCreateInput,
    CollaboratorCommitteeDeleteInput,
    CollaboratorCreateInput,
    CollaboratorOverview,
    CollaboratorUpdateInput,
    Committee,
    CommitteeCreateInput,
    CommitteeOverview,
    CommitteeUpdateInput,
    ContactCreateInput,
    ContactUpdateInput,
    DateTemplate,
    Event,
    EventCollaboratorCreateDeleteInput,
    EventCommitteeCreateDeleteInput,
    EventCreateInput,
    EventMemberCreateDeleteInput,
    EventOverview,
    EventPosterCreateInput,
    EventPosterUpdateInput,
    EventTagCreateDeleteInput,
    EventType,
    EventTypeCreateInput,
    EventTypeUpdateInput, EventUpdateInput,
    FileCommitteeCreateDeleteInput,
    FileCreateInput,
    FileEventCreateDeleteInput,
    FileNewsCreateDeleteInput,
    FileOverview,
    FileTagCreateDeleteInput,
    FileType,
    FileTypeCreateInput,
    FileTypeUpdateInput,
    FileUpdateInput,
    ItemWithMessage,
    Log,
    Member,
    MemberCommitteeBoardUpdateInput,
    MemberCommitteeCreateInput,
    MemberCommitteeImageUrlUpdateInput,
    MemberCommitteeLongDescriptionUpdateInput,
    MemberCommitteeShortDescriptionUpdateInput,
    MemberCommitteeTagUpdateInput,
    MemberJoinLeaveReport,
    MemberOverview,
    MemberRecaptchaCreateInput,
    MemberTemplateContent,
    Message,
    MessageItemList,
    News,
    NewsCollaboratorCreateDeleteInput,
    NewsCommitteeCreateDeleteInput,
    NewsCreateInput,
    NewsOverview,
    NewsTagCreateDeleteInput,
    NewsUpdateInput,
    SendContactRecaptchaCreateInput,
    UtilsAddressLookupResponseItem
} from './ApiModels'
import {getMemberId, getToken} from './Persistence'

function request(
    requestType: 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    body: {}
) {
    return {
        method: requestType,
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(body)
    }
}

// ---- AUTH

export async function authLoginRequest(
    mail: string,
    password: string
): Promise<AuthLoginResponse | Message> {
    const options = request('POST',
        {
            mail: mail,
            password: password
        }
    )
    return handleRestResponse(`${API_URL}/auth/login`, options)
}

export async function authLogoutRequest(
    token: string
) {
    const options = request('POST',
        {
            token: token
        }
    )
    return handleRestResponse(`${API_URL}/auth/logout`, options)
}

export async function authWhoAmIRequest(
    token: string
): Promise<AuthWhoAmIResponse | Message> {
    const options = request('POST',
        {
            token: token
        }
    )
    return handleRestResponse(`${API_URL}/auth/whoami`, options)
}

export async function authListRoles(): Promise<ItemWithMessage<AuthRoles> | Message> {
    const token = getToken()

    const options = request('POST',
        {token: token}
    )
    return handleRestResponse(`${API_URL}/auth/role/list`, options)
}

export async function authRoleCreate(
    memberId: string,
    role: string
): Promise<Message> {
    const token = getToken()

    const options = request('POST',
        {
            auth: {token: token},
            input: {
                memberId: memberId,
                role: role
            }
        }
    )
    return handleRestResponse(`${API_URL}/auth/role/create`, options)
}

export async function authRoleRead(
    memberId: string
): Promise<ItemWithMessage<string> | Message> {
    const token = getToken()

    const options = request('POST',
        {
            auth: {token: token},
            input: {memberId: memberId}
        }
    )
    return handleRestResponse(`${API_URL}/auth/role/read`, options)
}

export async function authRoleUpdate(
    memberId: string,
    role: string
): Promise<Message> {
    const token = getToken()

    const options = request('PATCH',
        {
            auth: {token: token},
            input: {
                memberId: memberId,
                role: role
            }
        }
    )
    return handleRestResponse(`${API_URL}/auth/role/update`, options)
}

export async function authRoleDelete(
    memberId: string
): Promise<Message> {
    const token = getToken()

    const options = request('DELETE',
        {
            auth: {token: token},
            input: {memberId: memberId}
        }
    )
    return handleRestResponse(`${API_URL}/auth/role/delete`, options)
}

// ---- AUTH

// ---- ADDRESS

export async function addressCreate(
    input: AddressCreateInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'POST', input,
        `${API_URL}/address/create`
    )
}

export async function addressRead(
    addressId: string
): Promise<ItemWithMessage<Address> | Message> {
    return handleAuthorizedRestReponse(
        'POST', addressId,
        `${API_URL}/address/read`
    )
}

export async function addressUpdate(
    input: AddressUpdateInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'PUT', input,
        `${API_URL}/address/update`
    )
}

export async function contactCreate(
    input: ContactCreateInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'POST', input,
        `${API_URL}/contact/create`
    )
}

export async function contactRead(): Promise<Message> {
    return handleRestResponseGet(`${API_URL}/contact/read`)
}

export async function contactUpdate(
    input: ContactUpdateInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'PUT', input,
        `${API_URL}/contact/update`
    )
}

export async function contactDelete(
    contactId: string
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'DELETE', contactId,
        `${API_URL}/contact/delete`
    )
}

// ---- ADDRESS

// ---- COLLABORATOR

export async function collaboratorCreate(
    input: CollaboratorCreateInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'POST', input,
        `${API_URL}/collaborator/create`
    )
}

export async function collaboratorCreateCommittee(
    input: CollaboratorCommitteeCreateInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'POST', input,
        `${API_URL}/collaborator/create/committee`
    )
}

export async function collaboratorRead(): Promise<Array<CollaboratorOverview> | Message> {
    return handleRestResponseGet(`${API_URL}/collaborator/read`)
}

export async function collaboratorReadById(
    collaboratorId: string
): Promise<Collaborator | Message> {
    return handleRestResponseGet(`${API_URL}/collaborator/read/${collaboratorId}`)
}

export async function collaboratorUpdate(
    input: CollaboratorUpdateInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'PUT', input,
        `${API_URL}/collaborator/update`
    )
}

export async function collaboratorUpdateAddress(
    input: CollaboratorAddressUpdateInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'PUT', input,
        `${API_URL}/collaborator/update/address`
    )
}

export async function collaboratorDelete(
    collaboratorId: string
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'DELETE', collaboratorId,
        `${API_URL}/collaborator/delete`
    )
}

export async function collaboratorDeleteCommittee(
    input: CollaboratorCommitteeDeleteInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'DELETE', input,
        `${API_URL}/collaborator/delete/committee`
    )
}

// ---- COLLABORATOR

// ---- COMMITTEE

export async function committeeCreate(
    input: CommitteeCreateInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'POST', input,
        `${API_URL}/committee/create`
    )
}

export async function committeeRead(): Promise<Array<CommitteeOverview> | Message> {
    return handleRestResponseGet(`${API_URL}/committee/read`)
}

export async function committeeReadById(
    committeeId: string
): Promise<Committee | Message> {
    return handleRestResponseGet(`${API_URL}/committee/read/${committeeId}`)
}

export async function committeeUpdate(
    input: CommitteeUpdateInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'PUT', input,
        `${API_URL}/committee/update`
    )
}

// ---- COMMITTEE

// ---- EVENT

export async function eventCreate(
    input: EventCreateInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'POST', input,
        `${API_URL}/event/create`
    )
}

export async function eventCreateCollaborator(
    input: EventCollaboratorCreateDeleteInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'POST', input,
        `${API_URL}/event/create/collaborator`
    )
}

export async function eventCreateCommittee(
    input: EventCommitteeCreateDeleteInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'POST', input,
        `${API_URL}/event/create/committee`
    )
}

export async function eventCreateJoinedMember(
    input: EventMemberCreateDeleteInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'POST', input,
        `${API_URL}/event/create/joinedMember`
    )
}

export async function eventCreateOrganizer(
    input: EventMemberCreateDeleteInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'POST', input,
        `${API_URL}/event/create/organizer`
    )
}

export async function eventCreatePoster(
    input: EventPosterCreateInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'POST', input,
        `${API_URL}/event/create/poster`
    )
}

export async function eventCreateTag(
    input: EventTagCreateDeleteInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'POST', input,
        `${API_URL}/event/create/tag`
    )
}

export async function eventCreateType(
    input: EventTypeCreateInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'POST', input,
        `${API_URL}/event/create/type`
    )
}

export async function eventRead(): Promise<Array<EventOverview> | Message> {
    return handleRestResponseGet(`${API_URL}/event/read/`)
}

export async function eventReadById(
    eventId: string
): Promise<Event | Message> {
    return handleRestResponseGet(`${API_URL}/event/read/${eventId}`)
}

export async function eventReadType(): Promise<Array<EventType> | Message> {
    return handleRestResponseGet(`${API_URL}/event/read/type`)
}

export async function eventUpdate(
    input: EventUpdateInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'PUT', input,
        `${API_URL}/event/update`
    )
}

export async function eventUpdatePoster(
    input: EventPosterUpdateInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'PUT', input,
        `${API_URL}/event/update/poster`
    )
}

export async function eventUpdateType(
    input: EventTypeUpdateInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'PUT', input,
        `${API_URL}/event/update/type`
    )
}

export async function eventDelete(
    eventId: string
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'DELETE', eventId,
        `${API_URL}/event/delete`
    )
}

export async function eventDeleteCollaborator(
    input: EventCollaboratorCreateDeleteInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'DELETE', input,
        `${API_URL}/event/delete/collaborator`
    )
}

export async function eventDeleteCommittee(
    input: EventCommitteeCreateDeleteInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'DELETE', input,
        `${API_URL}/event/delete/committee`
    )
}

export async function eventDeleteJoinedMember(
    input: EventMemberCreateDeleteInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'DELETE', input,
        `${API_URL}/event/delete/joinedMember`
    )
}

export async function eventDeleteOrganizer(
    input: EventMemberCreateDeleteInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'DELETE', input,
        `${API_URL}/event/delete/organizer`
    )
}

export async function eventDeletePoster(
    eventPosterId: string
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'DELETE', eventPosterId,
        `${API_URL}/event/delete/poster`
    )
}

export async function eventDeleteTag(
    input: EventTagCreateDeleteInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'DELETE', input,
        `${API_URL}/event/delete/tag`
    )
}

export async function eventDeleteType(
    eventTypeId: string
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'DELETE', eventTypeId,
        `${API_URL}/event/delete/type`
    )
}

// ---- EVENT

// ---- FILE

export async function fileCreate(
    input: FileCreateInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'POST', input,
        `${API_URL}/file/create`
    )
}

export async function fileCreateCommittee(
    input: FileCommitteeCreateDeleteInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'POST', input,
        `${API_URL}/file/create/committee`
    )
}

export async function fileCreateEvent(
    input: FileEventCreateDeleteInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'POST', input,
        `${API_URL}/file/create/event`
    )
}

export async function fileCreateNews(
    input: FileNewsCreateDeleteInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'POST', input,
        `${API_URL}/file/create/news`
    )
}

export async function fileCreateTag(
    input: FileTagCreateDeleteInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'POST', input,
        `${API_URL}/file/create/tag`
    )
}

export async function fileCreateType(
    input: FileTypeCreateInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'POST', input,
        `${API_URL}/file/create/type`
    )
}

export async function fileRead(): Promise<Array<FileOverview> | Message> {
    return handleRestResponseGet(`${API_URL}/file/read`)
}

export async function fileReadById(
    fileId: string
): Promise<File | Message> {
    return handleRestResponseGet(`${API_URL}/file/read/${fileId}`)
}

export async function fileReadType(): Promise<Array<FileType> | Message> {
    return handleRestResponseGet(`${API_URL}/file/read/type`)
}

export async function fileUpdate(
    input: FileUpdateInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'PUT', input,
        `${API_URL}/file/update`
    )
}

export async function fileUpdateType(
    input: FileTypeUpdateInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'PUT', input,
        `${API_URL}/file/update/type`
    )
}

export async function fileDelete(
    fileId: string
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'DELETE', fileId,
        `${API_URL}/file/delete`
    )
}

export async function fileDeleteCommittee(
    input: FileCommitteeCreateDeleteInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'DELETE', input,
        `${API_URL}/file/delete/committee`
    )
}

export async function fileDeleteEvent(
    input: FileEventCreateDeleteInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'DELETE', input,
        `${API_URL}/file/delete/event`
    )
}

export async function fileDeleteNews(
    input: FileNewsCreateDeleteInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'DELETE', input,
        `${API_URL}/file/delete/news`
    )
}

export async function fileDeleteTag(
    input: FileTagCreateDeleteInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'DELETE', input,
        `${API_URL}/file/delete/tag`
    )
}

export async function fileDeleteType(
    fileTypeId: string
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'DELETE', fileTypeId,
        `${API_URL}/file/delete/type`
    )
}

// ---- FILE

// ---- NEWS

export async function newsCreate(
    input: NewsCreateInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'POST', input,
        `${API_URL}/news/create`
    )
}

export async function newsCreateCollaborator(
    input: NewsCollaboratorCreateDeleteInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'POST', input,
        `${API_URL}/news/create/collaborator`
    )
}

export async function newsCreateCommittee(
    input: NewsCommitteeCreateDeleteInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'POST', input,
        `${API_URL}/news/create/committee`
    )
}

export async function newsCreateTag(
    input: NewsTagCreateDeleteInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'POST', input,
        `${API_URL}/news/create/tag`
    )
}

export async function newsRead(): Promise<Array<NewsOverview> | Message> {
    return handleRestResponseGet(`${API_URL}/news/read`)
}

export async function newsReadById(
    newsId: string
): Promise<News | Message> {
    return handleRestResponseGet(`${API_URL}/news/read/${newsId}`)
}

export async function newsUpdate(
    input: NewsUpdateInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'PUT', input,
        `${API_URL}/news/update`
    )
}

export async function newsDelete(
    newsId: string
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'DELETE', newsId,
        `${API_URL}/news/delete`
    )
}

export async function newsDeleteCollaborator(
    input: NewsCollaboratorCreateDeleteInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'DELETE', input,
        `${API_URL}/news/delete/collaborator`
    )
}

export async function newsDeleteCommittee(
    input: NewsCommitteeCreateDeleteInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'DELETE', input,
        `${API_URL}/news/delete/committee`
    )
}

export async function newsDeleteTag(
    input: NewsTagCreateDeleteInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'DELETE', input,
        `${API_URL}/news/delete/tag`
    )
}

// ---- NEWS

// ---- STATISTICS

export async function statisticsTimelineJoinLeave(
    startDateInclusive: DateTemplate | null,
    endDateInclusive: DateTemplate | null
): Promise<ItemWithMessage<MemberJoinLeaveReport> | Message> {
    const token = getToken()

    const options = request('POST',
        {
            auth: {token: token},
            input: {
                startDateInclusive: startDateInclusive,
                endDateInclusive: endDateInclusive
            }
        }
    )
    return handleRestResponse(`${API_URL}/statistics/timeline/joinLeave`, options)
}

// ---- STATISTICS

// ---- UTILS

export async function utilsAddressLookup(
    postalCode: string,
    houseNumber: number
): Promise<MessageItemList<UtilsAddressLookupResponseItem> | Message> {
    const options = request('POST',
        {
            postalCode: postalCode,
            houseNumber: houseNumber
        }
    )
    return handleRestResponse(`${API_URL}/utils/address/lookup`, options)
}

export async function utilsPattern(
    item: string,
    extension: string
): Promise<boolean | Message> {
    const options = request('POST', item)
    return handleRestResponse(`${API_URL}${extension}`, options)
}

export async function utilsPatternMail(
    mail: string
): Promise<boolean | Message> {
    return utilsPattern(mail, "/utils/pattern/mail")
}

export async function utilsPatternPostalCode(
    postalCode: string
): Promise<boolean | Message> {
    return utilsPattern(postalCode, "/utils/pattern/postalCode")
}

export async function utilsPatternStudentNumber(
    studentNumber: string
): Promise<boolean | Message> {
    return utilsPattern(studentNumber, "/utils/pattern/studentNumber")
}

// ---- UTILS

// ---- MEMBER

export async function memberCreateRecaptcha(
    memberRecaptcha: MemberRecaptchaCreateInput
): Promise<Message> {
    const options = request('POST', memberRecaptcha)
    return handleRestResponse(`${API_URL}/member/create/recaptcha`, options)
}

export async function sendContactRecaptcha(
    contactRecaptcha: SendContactRecaptchaCreateInput
): Promise<Message> {
    const options = request('POST', contactRecaptcha)
    return handleRestResponse(`${API_URL}/contact/mail/recaptcha`, options)
}

export async function memberReadCurrentLoggedInUser(): Promise<ItemWithMessage<Member> | Message> {
    const memberId = getMemberId()
    return memberReadById(memberId)
}

export async function memberReadById(memberId: string | null): Promise<ItemWithMessage<Member> | Message> {
    const token = getToken()

    const options = request('POST',
        {
            auth: {token: token},
            input: memberId
        }
    )
    return handleRestResponse(`${API_URL}/member/read`, options)
}

export async function memberReadByStudentNumber(
    studentNumber: string
): Promise<ItemWithMessage<MemberOverview> | Message> {
    const token = getToken()

    const options = request('POST',
        {
            auth: {token: token},
            input: studentNumber
        }
    )
    return handleRestResponse(`${API_URL}/member/read/studentNumber`, options)
}

export async function memberReadByMail(
    mail: string
): Promise<ItemWithMessage<MemberOverview> | Message> {
    const token = getToken()

    const options = request('POST',
        {
            auth: {token: token},
            input: mail
        }
    )
    return handleRestResponse(`${API_URL}/member/read/mail`, options)
}

export async function memberUpdateFirstMiddleAndLastName(
    firstName: string,
    middleName: string,
    lastName: string
): Promise<Message> {
    const token = getToken()
    const memberId = getMemberId()

    const options = request('PATCH',
        {
            auth: {token: token},
            input: {
                id: memberId,
                firstName: firstName,
                middleName: middleName,
                lastName: lastName
            }
        }
    )
    return handleRestResponse(`${API_URL}/member/update/firstMiddleAndLastName`, options)
}

export async function memberUpdateAddress(
    city: string,
    houseNumber: number,
    houseNumberAddition: string | null,
    houseNumberLetter: string | null,
    postalCode: string,
    streetName: string
): Promise<Message> {
    const token = getToken()
    const memberId = getMemberId()

    const options = request('PATCH',
        {
            auth: {token: token},
            input: {
                id: memberId,
                address: {
                    city: city,
                    houseNumber: houseNumber,
                    houseNumberAddition: houseNumberAddition,
                    houseNumberLetter: houseNumberLetter,
                    postalCode: postalCode,
                    streetName: streetName
                }
            }
        }
    )
    return handleRestResponse(`${API_URL}/member/update/address`, options)
}

export async function memberUpdateDateOfBirth(
    date: DateTemplate | null
): Promise<Message> {
    const token = getToken()
    const memberId = getMemberId()

    const options = request('PATCH',
        {
            auth: {token: token},
            input: {
                id: memberId,
                dateOfBirth: date
            }
        }
    )
    return handleRestResponse(`${API_URL}/member/update/dateOfBirth`, options)
}

export async function memberUpdateMail(
    mail: string
): Promise<Message> {
    const token = getToken()
    const memberId = getMemberId()

    const options = request('PATCH',
        {
            auth: {token: token},
            input: {
                id: memberId,
                mail: mail
            }
        }
    )
    return handleRestResponse(`${API_URL}/member/update/mail`, options)
}

export async function memberUpdatePasswordOfCurrentLoggedInUser(
    password: string,
    passwordRepeat: string
): Promise<Message> {
    const memberId = getMemberId()
    return memberUpdatePassword(memberId, password, passwordRepeat)
}

export async function memberUpdatePassword(
    memberId: string | null,
    password: string,
    passwordRepeat: string
): Promise<Message> {
    const token = getToken()

    const options = request('PATCH',
        {
            auth: {token: token},
            input: {
                id: memberId,
                password: {
                    password: password,
                    passwordRepeat: passwordRepeat
                }
            }
        }
    )
    return handleRestResponse(`${API_URL}/member/update/password`, options)
}

export async function memberUpdateSetActive(
    memberId: string | null
): Promise<Message> {
    const token = getToken()

    const options = request('PATCH',
        {
            auth: {token: token},
            input: memberId
        }
    )
    return handleRestResponse(`${API_URL}/member/update/setActive`, options)
}

export async function memberUpdateSetInactive(
    memberId: string | null
): Promise<Message> {
    const token = getToken()

    const options = request('PATCH',
        {
            auth: {token: token},
            input: memberId
        }
    )
    return handleRestResponse(`${API_URL}/member/update/setInactive`, options)
}

export async function memberUpdateSettings(
    showExternal: boolean,
    alumni: boolean
): Promise<Message> {
    const token = getToken()
    const memberId = getMemberId()

    const options = request('PATCH',
        {
            auth: {token: token},
            input: {
                id: memberId,
                settings: {
                    showExternal: showExternal,
                    alumni: alumni
                }
            }
        }
    )
    return handleRestResponse(`${API_URL}/member/update/settings`, options)
}

export async function memberUpdateStudentNumber(
    memberId: string,
    studentNumber: string | null
): Promise<Message> {
    const token = getToken()

    const options = request('PATCH',
        {
            auth: {token: token},
            input: {
                id: memberId,
                studentNumber: studentNumber
            }
        }
    )
    return handleRestResponse(`${API_URL}/member/update/studentNumber`, options)
}

// ---- MEMBER

// ---- MEMBER COMMITTEE

export async function memberCommitteeCreate(
    input: MemberCommitteeCreateInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'POST', input,
        `${API_URL}/memberCommittee/create`
    )
}

export async function memberCommitteeUpdateBoardAdd(
    input: MemberCommitteeBoardUpdateInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'PATCH', input,
        `${API_URL}/memberCommittee/update/board/add`
    )
}

export async function memberCommitteeUpdateBoardRemove(
    input: MemberCommitteeBoardUpdateInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'PATCH', input,
        `${API_URL}/memberCommittee/update/board/remove`
    )
}

export async function memberCommitteeUpdateChairmanTagAdd(
    input: MemberCommitteeTagUpdateInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'PATCH', input,
        `${API_URL}/memberCommittee/update/chairmanTag/add`
    )
}

export async function memberCommitteeUpdateChairmanTagRemove(
    input: MemberCommitteeTagUpdateInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'PATCH', input,
        `${API_URL}/memberCommittee/update/chairmanTag/remove`
    )
}

export async function memberCommitteeUpdateCommitteeTagAdd(
    input: MemberCommitteeTagUpdateInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'PATCH', input,
        `${API_URL}/memberCommittee/update/committeeTag/add`
    )
}

export async function memberCommitteeUpdateCommitteeTagRemove(
    input: MemberCommitteeTagUpdateInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'PATCH', input,
        `${API_URL}/memberCommittee/update/committeeTag/remove`
    )
}

export async function memberCommitteeUpdateImageUrl(
    input: MemberCommitteeImageUrlUpdateInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'PATCH', input,
        `${API_URL}/memberCommittee/update/imageUrl`
    )
}

export async function memberCommitteeUpdateShortDescription(
    input: MemberCommitteeShortDescriptionUpdateInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'PATCH', input,
        `${API_URL}/memberCommittee/update/shortDescription`
    )
}

export async function memberCommitteeUpdateLongDescription(
    input: MemberCommitteeLongDescriptionUpdateInput
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'PATCH', input,
        `${API_URL}/memberCommittee/update/longDescription`
    )
}

export async function memberCommitteeDelete(
    memberId: string
): Promise<Message> {
    return handleAuthorizedRestReponse(
        'DELETE', memberId,
        `${API_URL}/memberCommittee/delete`
    )
}

// ---- MEMBER COMMITTEE

// ---- MEMBER TEMPLATE

export async function memberTemplateRead(
    id: string,
    studentNumber: string
): Promise<MemberTemplateContent | Message> {
    const options = request('POST',
        {
            id: id,
            studentNumber: studentNumber
        }
    )
    return handleRestResponse(`${API_URL}/memberTemplate/read`, options)
}

export async function memberTemplateDelete(
    id: string,
    studentNumber: string
): Promise<Message> {
    const options = request('DELETE',
        {
            id: id,
            studentNumber: studentNumber
        }
    )
    return handleRestResponse(`${API_URL}/memberTemplate/delete`, options)
}

export async function memberTemplateCreate(
    input: string | null
): Promise<Message> {
    const token = getToken()

    const options = request('POST',
        {
            auth: {token: token},
            input: input !== null ? JSON.parse(input) : null
        }
    )
    return handleRestResponse(`${API_URL}/memberTemplate/create`, options)
}

export async function memberTemplateCreateBulk(
    input: string | null
): Promise<Message> {
    const token = getToken()

    const options = request('POST',
        {
            auth: {token: token},
            input: input !== null ? JSON.parse(input) : null
        }
    )
    return handleRestResponse(`${API_URL}/memberTemplate/create/bulk`, options)
}

// ---- MEMBER TEMPLATE

// ---- LOGGING

export async function loggingReadErrors(): Promise<ItemWithMessage<Array<Log>> | Message> {
    const token = getToken()

    const options = request('POST',
        {token: token}
    )
    return handleRestResponse(`${API_URL}/log/error`, options)
}

export async function loggingReadByReferenceId(
    referenceId: string | null
): Promise<ItemWithMessage<Array<Log>> | Message> {
    const token = getToken()

    const options = request('POST',
        {
            auth: {token: token},
            input: referenceId
        }
    )
    return handleRestResponse(`${API_URL}/log/reference`, options)
}

export async function loggingDeleteByReferenceId(
    referenceId: string | null
): Promise<ItemWithMessage<Array<Log>> | Message> {
    const token = getToken()

    const options = request('DELETE',
        {
            auth: {token: token},
            input: referenceId
        }
    )
    return handleRestResponse(`${API_URL}/log/delete`, options)
}

// ---- LOGGING

async function handleAuthorizedRestReponse<T, A>(
    requestType: 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    input: A,
    url: string
): Promise<T | Message> {
    const token = getToken()
    return handleRestResponse(url, request(requestType,
        {
            auth: {token: token},
            input: input
        }
    ))
}

async function handleRestResponseGet<T>(
    url: string
): Promise<T | Message> {
    try {
        const response = await fetch(url)
        if (response.status >= 200 && response.status < 300) {
            return Promise.resolve(response.json()) as Promise<T>
        } else {
            const message: Message = {
                successful: false,
                messageType: "ERROR",
                authType: "",
                message: "The backend returned an error, please contact an admin",
                targetId: "",
                memberId: ""
            }
            return message
        }
    } catch (e) {
        const message: Message = {
            successful: false,
            messageType: "ERROR",
            authType: "",
            message: "The backend is currently unavailable, please try again later",
            targetId: "",
            memberId: ""
        }
        return message
    }
}

async function handleRestResponse<T>(
    url: string,
    options: RequestInit
): Promise<T | Message> {
    try {
        const response = await fetch(url, options)
        if (response.status >= 200 && response.status < 300) {
            return Promise.resolve(response.json()) as Promise<T>
        } else {
            const message: Message = {
                successful: false,
                messageType: "ERROR",
                authType: "",
                message: "The backend returned an error, please contact an admin",
                targetId: "",
                memberId: ""
            }
            return message
        }
    } catch (e) {
        const message: Message = {
            successful: false,
            messageType: "ERROR",
            authType: "",
            message: "The backend is currently unavailable, please try again later",
            targetId: "",
            memberId: ""
        }
        return message
    }
}