import React, {ReactElement} from "react";
import {EventOverview} from "../ApiModels";
import '../home/Home.css';
import '../Form.css'
import './Content.css';

type EventModuleProps = {
    editSetter: (event: EventOverview) => void
    deleteSetter: (event: EventOverview) => void
    events: Array<EventOverview> | null
}

export default class EventModule extends React.Component<EventModuleProps, {}> {

    createEventBox(event: EventOverview) {
        return (
            <div className='event-list-item'>
                <div className='edit-event-box'>
                    <div className="event-edit-container">
                        <div className="event-edit-button" onClick={
                            () => this.props.editSetter(event)
                        }>
                            <i className="fas fa-pencil-alt"/>
                        </div>
                        <div className="event-edit-button delete" onClick={
                            () => this.props.deleteSetter(event)
                        }>
                            <i className="fas fa-trash-alt"/>
                        </div>
                    </div>
                    <div className='home-event-image-container'>
                        <img src={event.imgUrl} alt=''/>
                    </div>
                    <div className='home-event-data-container'>
                        <h3>{event.title}</h3>
                        <h5>{event.subTitle}</h5>
                        <h5>{event.subject}</h5>
                        <h5>{event.shortDescription}</h5>
                    </div>
                </div>
            </div>)
    }

    createGrid(events: Array<EventOverview>) {
        var grid: ReactElement = <div className='event-list-container'/>;
        var divArr: ReactElement[] = [];
        for (var i = 0; i < events.length; i++) {
            divArr.push(this.createEventBox(events[i]))
            if (i < events.length - 1) {
                divArr.push(<hr/>);
            }
        }
        grid = React.cloneElement(grid, {children: divArr});
        return grid;
    }

    render() {
        return this.props.events != null ?
            <div>
                {this.createGrid(this.props.events)}
            </div>
            : undefined

    }
}