import React from 'react'
import './Home.css'
import '../Form.css'
import {EventOverview, Message,} from '../ApiModels'
import EventGrid from "./EventGrid";
import {isMessage} from "../Helper";
import {eventRead} from "../Requests";
import {ASSETS_URL} from "../Global";
import EventOverviewComponent from "./EventOverview";

interface HomeState {
    error: any
    isLoaded: boolean
    events: EventOverview[]
    selectedEvent: EventOverview | null
}

export default class Home extends React.Component<{}, HomeState> {

    constructor() {
        super({});
        this.state = {
            error: null,
            isLoaded: false,
            events: [],
            selectedEvent: null
        };
    }

    public getDescriptionBoxID() {
        let c = document.getElementById('descriptionbox')
        return (
            c
        )
    }

    async componentDidMount() {
        var res: EventOverview[] = [];
        await eventRead().then(events => {
            if (!isMessage(events))
                res = events;
            else {
                var message: Message = (events as Message);
                console.log(message.messageType, message.message);
            }
        });
        this.setState({
            isLoaded: true,
            events: res
        })
    }


    public render() {
        return (

            <div>
                <div className="home-center">
                    <img
                        className="home-logo-image"
                        src={ASSETS_URL + "/logo/white_transparent.png"}
                        alt="CSAR Logo"/>
                    <div className="image-separator image-separator-bottom ">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                            <path className="svg-white-bg" d="M500.2,94.7L0,0v100h1000V0L500.2,94.7z"></path>
                        </svg>
                    </div>
                </div>

                <EventGrid
                    setter={(event: EventOverview) => this.setState({selectedEvent: event}, () => {
                        const targetBox = this.getDescriptionBoxID();
                        if (targetBox != null) {
                            targetBox.scrollIntoView({block: 'end', behavior: 'smooth'})
                        }
                    })}
                    events={this.state.events}
                />

                <EventOverviewComponent event={this.state.selectedEvent}/>
            </div>
        )
    }
}