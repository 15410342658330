import React from "react";
import './Home.css';
import '../Form.css';
import {EventOverview,} from "../ApiModels";


type EventOverviewProps = {
    event: EventOverview | null
}


export default class EventOverviewComponent extends React.Component<EventOverviewProps, {}> {
    render() {
        return (
            <div className='form-container'>
                <div className='home-event-overview-box' id='descriptionbox'>
                    {this.props.event !== null
                        ? (
                            <div className="home-event-overview-details">
                                <div className="home-event-overview-info">
                                    <div>
                                        <i className="far fa-calendar-alt home-event-icon"/>
                                        {('0' + this.props.event.startDate.day).slice(-2)} - {('0' + this.props.event.startDate.month).slice(-2)} - {this.props.event.startDate.year}
                                    </div>
                                    <div>
                                        <i className="far fa-clock home-event-icon"/>
                                        {('0' + this.props.event.startTime.hours).slice(-2)} : {('0' + this.props.event.startTime.minutes).slice(-2)}
                                    </div>
                                    <div>
                                        <i className="fas fa-map-marker-alt home-event-icon"/>
                                        {this.props.event.address !== null && this.props.event.address.houseNumber !== null ?
                                            this.props.event.address.streetName.concat(" ", this.props.event.address.houseNumber.toPrecision(), " , ", this.props.event.address.city)
                                            : undefined}
                                    </div>
                                    <div>
                                        <i className="fas fa-euro-sign home-event-icon"/>
                                        {'€ ' + this.props.event.price.toFixed(2)}
                                    </div>
                                </div>
                                <img src={this.props.event.imgUrl} alt=''/>
                                <h1>{this.props.event.title}</h1>
                                <h2>{this.props.event.subTitle}</h2>
                                <p>{this.props.event.subject}</p>
                                <p>{this.props.event.longDescription}</p>
                                <p>{this.props.event.content}</p>
                                <p>{this.props.event.link}</p>

                            </div>
                        )
                        : <p>Klik op een event om hier meer informatie weer te geven</p>}
                </div>
            </div>
        )
    }
}