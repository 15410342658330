import RequestModule, {Props} from "../admin/RequestModule";

export default class DynamicRequestModule<T> extends RequestModule<T> {
    componentDidUpdate(prevProps: Readonly<Props<T>>, prevState: Readonly<T>,): void {
        if(this.props.state !== prevProps.state){
            this.setState(this.props.state);
            this.forceUpdate();
        }
    }

    opened = true
}