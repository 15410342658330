import React from 'react'
import {Redirect} from 'react-router-dom'
import {
    AddressUpdateInput,
    EventCollaboratorCreateDeleteInput,
    EventCommitteeCreateDeleteInput, EventEditInput,
    EventMemberCreateDeleteInput,
    EventOverview,
    EventPosterCreateInput,
    EventPosterUpdateInput,
    EventType,
    EventTypeUpdateInput, EventUpdateInput,
    Message,
    UserLoggedIn
} from '../ApiModels'
import {EventCreateInput} from '../admin/components/Event'
import RequestModule from '../admin/RequestModule';
import DynamicRequestModule from "./DynamicRequestModule";
import {
    addressUpdate,
    eventCreate,
    eventCreateCollaborator,
    eventCreateCommittee,
    eventCreateJoinedMember,
    eventCreateOrganizer,
    eventCreatePoster,
    eventDelete,
    eventRead, eventReadById,
    eventReadType,
    eventUpdate,
    eventUpdatePoster,
    eventUpdateType
} from '../Requests';
import {Create, Delete, RequestMethod, Update} from '../Global';
import {isMessage, toNumber, toNumberOrNull} from '../Helper';

import './Content.css'
import EventModule from "./EventModule";


interface contentProps {
    user: UserLoggedIn
}

interface contentState {
    eventTypeIdOptions: Array<EventType> | undefined
    events: Array<EventOverview> | undefined
    selectedEditEvent: EventEditInput
    selectedDeleteEvent: EventDeleteInput
}

export default class Content extends React.Component<contentProps, contentState> {
    constructor(props: any) {
        super(props)
        this.state = {
            eventTypeIdOptions: undefined,
            events: undefined,
            selectedEditEvent: {
                id: "",
                title: "",
                subTitle: "",
                shortDescription: "",
                longDescription: "",
                imgUrl: "",
                content: "",
                eventTypeId: "",
                subject: "",
                addressid: "",
                streetName: "",
                houseNumber: 0,
                houseNumberLetter: null,
                houseNumberAddition: null,
                postalCode: "",
                city: "",

                addressRoom: null,
                schoolYear: 0,

                startDateYear: 0,
                startDateMonth: 0,
                startDateDay: 0,

                endDateYear: null,
                endDateMonth: null,
                endDateDay: null,

                startTimeHours: 0,
                startTimeMinutes: 0,
                startTimeSeconds: 0,

                endTimeHours: 0,
                endTimeMinutes: 0,
                endTimeSeconds: 0,

                price: 0.0,
                link: null
            },
            selectedDeleteEvent: {title: '', id: ''}
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.passEventEditData = this.passEventEditData.bind(this);
        this.passEventDeleteData = this.passEventDeleteData.bind(this);
    }

    private async handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()

        this.setState({})
    }

    async componentDidMount() {
        let events = await eventReadType()
        if (!isMessage(events)) {
            this.setState({...this.state, eventTypeIdOptions: events})
        }

        let res: EventOverview[] = [];
        await eventRead().then(events => {
            if (!isMessage(events))
                res = events;
            else {
                let message: Message = (events as Message);
                console.log(message.messageType, message.message);
            }
        });
        this.setState({...this.state, events: res})
    }

    public async passEventEditData(event: EventOverview) {
        let e = await eventReadById(event.id);
        if (!isMessage(e)) {
            if (e.address !== null && e.eventType !== null && e.address.houseNumber !== null) {
                let eei: EventEditInput = {

                    id: e.id,

                    title: e.title,
                    subTitle: e.subTitle,
                    shortDescription: e.shortDescription,
                    longDescription: e.longDescription,
                    imgUrl: e.imgUrl,
                    content: e.content,

                    eventTypeId: e.eventType.id,

                    subject: e.subject,

                    addressid: e.address.id,

                    streetName: e.address.streetName,
                    houseNumber: e.address.houseNumber,
                    houseNumberLetter: e.address.houseNumberLetter,
                    houseNumberAddition: e.address.houseNumberAddition,
                    postalCode: e.address.postalCode,
                    city: e.address.city,

                    addressRoom: e.addressRoom,
                    schoolYear: 0,

                    startDateYear: e.startDate.year,
                    startDateMonth: e.startDate.month,
                    startDateDay: e.startDate.day,

                    endDateYear: null,
                    endDateMonth: null,
                    endDateDay: null,

                    startTimeHours: 0,
                    startTimeMinutes: 0,
                    startTimeSeconds: 0,

                    endTimeHours: 0,
                    endTimeMinutes: 0,
                    endTimeSeconds: 0,

                    price: e.price,
                    link: e.link
                };
                this.setState({selectedEditEvent: eei});

                const targetBox = document.getElementById('event-edit-module');
                if (targetBox != null) {
                    targetBox.scrollIntoView({block: 'start', behavior: 'smooth'})
                }
            }
        } else {
            let message: Message = (e as Message);
            console.log(message.messageType, message.message);
        }
    }

    public passEventDeleteData(event: EventOverview) {
        this.setState({selectedDeleteEvent: {title: event.title, id: event.id}})
        const targetBox = document.getElementById('event-delete-module');
        if (targetBox != null) {
            targetBox.scrollIntoView({block: 'start', behavior: 'smooth'})
        }
    }

    public render() {
        return !this.props.user.loggedIn ? (
                <Redirect to="/login"/>
            ) :
            <div className="content-container">
                <div className="content-box-static">
                    <h1>Content Panel</h1>
                    <br/>
                    {this.state.events !== undefined ?
                        <EventModule events={this.state.events} editSetter={this.passEventEditData}
                                     deleteSetter={this.passEventDeleteData}/> : undefined}

                    {/* Update */}
                    <div id='event-edit-module'>
                        <DynamicRequestModule<EventEditInput>
                            user={this.props.user}
                            title="Update Event"
                            path="/event/update"
                            requestMethod={RequestMethod.PUT}
                            state={this.state.selectedEditEvent}
                            permissionsInfo={[]}
                            permissionsCreate={[]}
                            permissionsRead={[]}
                            permissionsUpdate={[Update.EVENT]}
                            permissionsDelete={[]}
                            items={[
                                {
                                    key: "title",
                                    type: "text",
                                    getter: (thiz: RequestModule<EventEditInput>) => thiz.state.title,
                                    setter: (v: any, thiz: RequestModule<EventEditInput>) => thiz.setState({title: v})
                                },
                                {
                                    key: "subTitle",
                                    type: "text",
                                    getter: (thiz: RequestModule<EventEditInput>) => thiz.state.subTitle,
                                    setter: (v: any, thiz: RequestModule<EventEditInput>) => thiz.setState({subTitle: v})
                                },
                                {
                                    key: "shortDescription",
                                    type: "text",
                                    getter: (thiz: RequestModule<EventEditInput>) => thiz.state.shortDescription,
                                    setter: (v: any, thiz: RequestModule<EventEditInput>) => thiz.setState({shortDescription: v})
                                },
                                {
                                    key: "longDescription",
                                    type: "text",
                                    getter: (thiz: RequestModule<EventEditInput>) => thiz.state.longDescription,
                                    setter: (v: any, thiz: RequestModule<EventEditInput>) => thiz.setState({longDescription: v})
                                },
                                {
                                    key: "imgUrl",
                                    type: "text",
                                    getter: (thiz: RequestModule<EventEditInput>) => thiz.state.imgUrl,
                                    setter: (v: any, thiz: RequestModule<EventEditInput>) => thiz.setState({imgUrl: v})
                                },
                                {
                                    key: "content",
                                    type: "text",
                                    getter: (thiz: RequestModule<EventEditInput>) => thiz.state.content,
                                    setter: (v: any, thiz: RequestModule<EventEditInput>) => thiz.setState({content: v})
                                },
                                {
                                    key: "eventTypeId",
                                    type: "select",
                                    options: this.state.eventTypeIdOptions,
                                    getter: (thiz: RequestModule<EventEditInput>) => thiz.state.eventTypeId,
                                    setter: (v: any, thiz: RequestModule<EventEditInput>) => thiz.setState({eventTypeId: v})
                                },
                                {
                                    key: "subject",
                                    type: "text",
                                    getter: (thiz: RequestModule<EventEditInput>) => thiz.state.subject,
                                    setter: (v: any, thiz: RequestModule<EventEditInput>) => thiz.setState({subject: v})
                                },
                                {
                                    key: "streetName",
                                    type: "text",
                                    getter: (thiz: RequestModule<EventEditInput>) => thiz.state.streetName,
                                    setter: (v: any, thiz: RequestModule<EventEditInput>) => thiz.setState({streetName: v})
                                },
                                {
                                    key: "houseNumber",
                                    type: "number",
                                    getter: (thiz: RequestModule<EventEditInput>) => thiz.state.houseNumber,
                                    setter: (v: any, thiz: RequestModule<EventEditInput>) => thiz.setState({houseNumber: toNumber(parseInt(v))})
                                },
                                {
                                    key: "houseNumberLetter",
                                    type: "text",
                                    getter: (thiz: RequestModule<EventEditInput>) => thiz.state.houseNumberLetter,
                                    setter: (v: string, thiz: RequestModule<EventEditInput>) => thiz.setState({houseNumberLetter: (v.replace(" ", "").length > 0) ? v : null})
                                },
                                {
                                    key: "houseNumberAddition",
                                    type: "text",
                                    getter: (thiz: RequestModule<EventEditInput>) => thiz.state.houseNumberAddition,
                                    setter: (v: string, thiz: RequestModule<EventEditInput>) => thiz.setState({houseNumberAddition: (v.replace(" ", "").length > 0) ? v : null})
                                },
                                {
                                    key: "postalCode",
                                    type: "text",
                                    getter: (thiz: RequestModule<EventEditInput>) => thiz.state.postalCode,
                                    setter: (v: string, thiz: RequestModule<EventEditInput>) => thiz.setState({postalCode: v})
                                },
                                {
                                    key: "city",
                                    type: "text",
                                    getter: (thiz: RequestModule<EventEditInput>) => thiz.state.city,
                                    setter: (v: string, thiz: RequestModule<EventEditInput>) => thiz.setState({city: v})
                                },

                                {
                                    key: "addressRoom",
                                    type: "text",
                                    getter: (thiz: RequestModule<EventEditInput>) => thiz.state.addressRoom,
                                    setter: (v: string, thiz: RequestModule<EventEditInput>) => thiz.setState({addressRoom: (v.replace(" ", "").length > 0) ? v : null})
                                },
                                {
                                    key: "schoolYear",
                                    type: "number",
                                    getter: (thiz: RequestModule<EventEditInput>) => thiz.state.schoolYear,
                                    setter: (v: any, thiz: RequestModule<EventEditInput>) => thiz.setState({schoolYear: toNumber(parseInt(v))})
                                },

                                {
                                    key: "startDateYear",
                                    type: "number",
                                    getter: (thiz: RequestModule<EventEditInput>) => thiz.state.startDateYear,
                                    setter: (v: any, thiz: RequestModule<EventEditInput>) => thiz.setState({startDateYear: toNumber(parseInt(v))})
                                },
                                {
                                    key: "startDateMonth",
                                    type: "number",
                                    getter: (thiz: RequestModule<EventEditInput>) => thiz.state.startDateMonth,
                                    setter: (v: any, thiz: RequestModule<EventEditInput>) => thiz.setState({startDateMonth: toNumber(parseInt(v))})
                                },
                                {
                                    key: "startDateDay",
                                    type: "number",
                                    getter: (thiz: RequestModule<EventEditInput>) => thiz.state.startDateDay,
                                    setter: (v: any, thiz: RequestModule<EventEditInput>) => thiz.setState({startDateDay: toNumber(parseInt(v))})
                                },

                                {
                                    key: "endDateYear",
                                    type: "number",
                                    getter: (thiz: RequestModule<EventEditInput>) => thiz.state.endDateYear,
                                    setter: (v: any, thiz: RequestModule<EventEditInput>) => thiz.setState({endDateYear: toNumberOrNull(parseInt(v))})
                                },
                                {
                                    key: "endDateMonth",
                                    type: "number",
                                    getter: (thiz: RequestModule<EventEditInput>) => thiz.state.endDateMonth,
                                    setter: (v: any, thiz: RequestModule<EventEditInput>) => thiz.setState({endDateMonth: toNumberOrNull(parseInt(v))})
                                },
                                {
                                    key: "endDateDay",
                                    type: "number",
                                    getter: (thiz: RequestModule<EventEditInput>) => thiz.state.endDateDay,
                                    setter: (v: any, thiz: RequestModule<EventEditInput>) => thiz.setState({endDateDay: toNumberOrNull(parseInt(v))})
                                },

                                {
                                    key: "startTimeHours",
                                    type: "number",
                                    getter: (thiz: RequestModule<EventEditInput>) => thiz.state.startTimeHours,
                                    setter: (v: any, thiz: RequestModule<EventEditInput>) => thiz.setState({startTimeHours: toNumber(parseInt(v))})
                                },
                                {
                                    key: "startTimeMinutes",
                                    type: "number",
                                    getter: (thiz: RequestModule<EventEditInput>) => thiz.state.startTimeMinutes,
                                    setter: (v: any, thiz: RequestModule<EventEditInput>) => thiz.setState({startTimeMinutes: toNumber(parseInt(v))})
                                },
                                {
                                    key: "startTimeSeconds",
                                    type: "number",
                                    getter: (thiz: RequestModule<EventEditInput>) => thiz.state.startTimeSeconds,
                                    setter: (v: any, thiz: RequestModule<EventEditInput>) => thiz.setState({startTimeSeconds: toNumber(parseInt(v))})
                                },

                                {
                                    key: "endTimeHours",
                                    type: "number",
                                    getter: (thiz: RequestModule<EventEditInput>) => thiz.state.endTimeHours,
                                    setter: (v: any, thiz: RequestModule<EventEditInput>) => thiz.setState({endTimeHours: toNumber(parseInt(v))})
                                },
                                {
                                    key: "endTimeMinutes",
                                    type: "number",
                                    getter: (thiz: RequestModule<EventEditInput>) => thiz.state.endTimeMinutes,
                                    setter: (v: any, thiz: RequestModule<EventEditInput>) => thiz.setState({endTimeMinutes: toNumber(parseInt(v))})
                                },
                                {
                                    key: "endTimeSeconds",
                                    type: "number",
                                    getter: (thiz: RequestModule<EventEditInput>) => thiz.state.endTimeSeconds,
                                    setter: (v: any, thiz: RequestModule<EventEditInput>) => thiz.setState({endTimeSeconds: toNumber(parseInt(v))})
                                },

                                {
                                    key: "price",
                                    type: "number",
                                    getter: (thiz: RequestModule<EventEditInput>) => thiz.state.price,
                                    setter: (v: any, thiz: RequestModule<EventEditInput>) => thiz.setState({price: toNumber(parseInt(v))})
                                },
                                {
                                    key: "link",
                                    type: "text",
                                    getter: (thiz: RequestModule<EventEditInput>) => thiz.state.link,
                                    setter: (v: any, thiz: RequestModule<EventEditInput>) => thiz.setState({link: (v.replace(" ", "").length > 0 ? v : null)})
                                }
                            ]}
                            submit={(state) => {
                                const event: EventUpdateInput = {
                                    id: state.id,
                                    title: state.title,
                                    subTitle: state.subTitle,
                                    shortDescription: state.shortDescription,
                                    longDescription: state.longDescription,
                                    imgUrl: state.imgUrl,
                                    content: state.content,
                                    eventTypeId: state.eventTypeId,
                                    subject: state.subject,
                                    addressRoom: state.addressRoom,
                                    schoolYear: state.schoolYear,
                                    startDate: {
                                        year: state.startDateYear,
                                        month: state.startDateMonth,
                                        day: state.startDateDay
                                    },
                                    endDate: (state.endDateYear !== null && state.endDateMonth !== null && state.endDateDay !== null
                                            ? {
                                                year: state.endDateYear,
                                                month: state.endDateMonth,
                                                day: state.endDateDay
                                            }
                                            : null
                                    ),
                                    startTime: {
                                        hours: state.startTimeHours,
                                        minutes: state.startTimeMinutes,
                                        seconds: state.startTimeSeconds
                                    },
                                    endTime: {
                                        hours: state.endTimeHours,
                                        minutes: state.endTimeMinutes,
                                        seconds: state.endTimeSeconds
                                    },
                                    price: state.price,
                                    link: state.link
                                };
                                const address: AddressUpdateInput = {
                                    id: state.addressid,
                                    streetName: state.streetName,
                                    houseNumber: state.houseNumber,
                                    houseNumberLetter: state.houseNumberLetter,
                                    houseNumberAddition: state.houseNumberAddition,
                                    postalCode: state.postalCode,
                                    city: state.city

                                };
                                eventUpdate(event);
                                addressUpdate(address);
                                return undefined;
                            }}/>
                    </div>

                    {/* Delete */}
                    <div id='event-delete-module'>
                        <DynamicRequestModule<EventDeleteInput>
                            user={this.props.user}
                            title="Delete Event"
                            path="/event/delete"
                            requestMethod={RequestMethod.DELETE}
                            state={this.state.selectedDeleteEvent}
                            permissionsInfo={[]}
                            permissionsCreate={[]}
                            permissionsRead={[]}
                            permissionsUpdate={[]}
                            permissionsDelete={[Delete.EVENT]}
                            items={[
                                {
                                    key: "title",
                                    type: "text",
                                    getter: (thiz: RequestModule<EventDeleteInput>) => thiz.state.title,
                                    setter: (v: any, thiz: RequestModule<EventDeleteInput>) => thiz.setState({title: v})
                                },
                                {
                                    key: "id",
                                    type: "text",
                                    getter: (thiz: RequestModule<EventDeleteInput>) => thiz.state.id,
                                    setter: (v: any, thiz: RequestModule<EventDeleteInput>) => thiz.setState({id: v})
                                }
                            ]}
                            submit={(state) => eventDelete(state.id)}
                        />
                    </div>

                    {/* Create */}
                    <RequestModule<EventCreateInput>
                        user={this.props.user}
                        title="Create Event"
                        path="/event/create"
                        requestMethod={RequestMethod.POST}
                        state={{
                            title: "",
                            subTitle: "",
                            shortDescription: "",
                            longDescription: "",
                            imgUrl: "",
                            content: "",
                            eventTypeId: "",
                            subject: "",

                            streetName: "",
                            houseNumber: 0,
                            houseNumberLetter: null,
                            houseNumberAddition: null,
                            postalCode: "",
                            city: "",

                            addressRoom: null,
                            schoolYear: 0,

                            startDateYear: 0,
                            startDateMonth: 0,
                            startDateDay: 0,

                            endDateYear: null,
                            endDateMonth: null,
                            endDateDay: null,

                            startTimeHours: 0,
                            startTimeMinutes: 0,
                            startTimeSeconds: 0,

                            endTimeHours: 0,
                            endTimeMinutes: 0,
                            endTimeSeconds: 0,

                            price: 0.0,
                            link: null
                        }}
                        permissionsInfo={[]}
                        permissionsCreate={[Create.EVENT]}
                        permissionsRead={[]}
                        permissionsUpdate={[]}
                        permissionsDelete={[]}
                        items={[
                            {
                                key: "title",
                                type: "text",
                                getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.title,
                                setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({title: v})
                            },
                            {
                                key: "subTitle",
                                type: "text",
                                getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.subTitle,
                                setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({subTitle: v})
                            },
                            {
                                key: "shortDescription",
                                type: "text",
                                getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.shortDescription,
                                setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({shortDescription: v})
                            },
                            {
                                key: "longDescription",
                                type: "text",
                                getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.longDescription,
                                setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({longDescription: v})
                            },
                            {
                                key: "imgUrl",
                                type: "text",
                                getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.imgUrl,
                                setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({imgUrl: v})
                            },
                            {
                                key: "content",
                                type: "text",
                                getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.content,
                                setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({content: v})
                            },
                            {
                                key: "eventTypeId",
                                type: "select",
                                options: this.state.eventTypeIdOptions,
                                getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.eventTypeId,
                                setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({eventTypeId: v})
                            },
                            {
                                key: "subject",
                                type: "text",
                                getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.subject,
                                setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({subject: v})
                            },

                            {
                                key: "streetName",
                                type: "text",
                                getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.streetName,
                                setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({streetName: v})
                            },
                            {
                                key: "houseNumber",
                                type: "number",
                                getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.houseNumber,
                                setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({houseNumber: toNumber(parseInt(v))})
                            },
                            {
                                key: "houseNumberLetter",
                                type: "text",
                                getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.houseNumberLetter,
                                setter: (v: string, thiz: RequestModule<EventCreateInput>) => thiz.setState({houseNumberLetter: (v.replace(" ", "").length > 0) ? v : null})
                            },
                            {
                                key: "houseNumberAddition",
                                type: "text",
                                getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.houseNumberAddition,
                                setter: (v: string, thiz: RequestModule<EventCreateInput>) => thiz.setState({houseNumberAddition: (v.replace(" ", "").length > 0) ? v : null})
                            },
                            {
                                key: "postalCode",
                                type: "text",
                                getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.postalCode,
                                setter: (v: string, thiz: RequestModule<EventCreateInput>) => thiz.setState({postalCode: v})
                            },
                            {
                                key: "city",
                                type: "text",
                                getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.city,
                                setter: (v: string, thiz: RequestModule<EventCreateInput>) => thiz.setState({city: v})
                            },

                            {
                                key: "addressRoom",
                                type: "text",
                                getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.addressRoom,
                                setter: (v: string, thiz: RequestModule<EventCreateInput>) => thiz.setState({addressRoom: (v.replace(" ", "").length > 0) ? v : null})
                            },
                            {
                                key: "schoolYear",
                                type: "number",
                                getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.schoolYear,
                                setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({schoolYear: toNumber(parseInt(v))})
                            },

                            {
                                key: "startDateYear",
                                type: "number",
                                getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.startDateYear,
                                setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({startDateYear: toNumber(parseInt(v))})
                            },
                            {
                                key: "startDateMonth",
                                type: "number",
                                getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.startDateMonth,
                                setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({startDateMonth: toNumber(parseInt(v))})
                            },
                            {
                                key: "startDateDay",
                                type: "number",
                                getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.startDateDay,
                                setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({startDateDay: toNumber(parseInt(v))})
                            },

                            {
                                key: "endDateYear",
                                type: "number",
                                getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.endDateYear,
                                setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({endDateYear: toNumberOrNull(parseInt(v))})
                            },
                            {
                                key: "endDateMonth",
                                type: "number",
                                getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.endDateMonth,
                                setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({endDateMonth: toNumberOrNull(parseInt(v))})
                            },
                            {
                                key: "endDateDay",
                                type: "number",
                                getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.endDateDay,
                                setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({endDateDay: toNumberOrNull(parseInt(v))})
                            },

                            {
                                key: "startTimeHours",
                                type: "number",
                                getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.startTimeHours,
                                setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({startTimeHours: toNumber(parseInt(v))})
                            },
                            {
                                key: "startTimeMinutes",
                                type: "number",
                                getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.startTimeMinutes,
                                setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({startTimeMinutes: toNumber(parseInt(v))})
                            },
                            {
                                key: "startTimeSeconds",
                                type: "number",
                                getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.startTimeSeconds,
                                setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({startTimeSeconds: toNumber(parseInt(v))})
                            },

                            {
                                key: "endTimeHours",
                                type: "number",
                                getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.endTimeHours,
                                setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({endTimeHours: toNumber(parseInt(v))})
                            },
                            {
                                key: "endTimeMinutes",
                                type: "number",
                                getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.endTimeMinutes,
                                setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({endTimeMinutes: toNumber(parseInt(v))})
                            },
                            {
                                key: "endTimeSeconds",
                                type: "number",
                                getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.endTimeSeconds,
                                setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({endTimeSeconds: toNumber(parseInt(v))})
                            },

                            {
                                key: "price",
                                type: "number",
                                getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.price,
                                setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({price: toNumber(parseInt(v))})
                            },
                            {
                                key: "link",
                                type: "text",
                                getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.link,
                                setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({link: (v.replace(" ", "").length > 0 ? v : null)})
                            }
                        ]}
                        submit={(state) => eventCreate({
                            title: state.title,
                            subTitle: state.subTitle,
                            shortDescription: state.shortDescription,
                            longDescription: state.longDescription,
                            imgUrl: state.imgUrl,
                            content: state.content,
                            eventTypeId: state.eventTypeId,
                            subject: state.subject,
                            address: {
                                streetName: state.streetName,
                                houseNumber: state.houseNumber,
                                houseNumberLetter: state.houseNumberLetter,
                                houseNumberAddition: state.houseNumberAddition,
                                postalCode: state.postalCode,
                                city: state.city
                            },
                            addressRoom: state.addressRoom,
                            schoolYear: state.schoolYear,
                            startDate: {
                                year: state.startDateYear,
                                month: state.startDateMonth,
                                day: state.startDateDay
                            },
                            endDate: (state.endDateYear !== null && state.endDateMonth !== null && state.endDateDay !== null
                                    ? {
                                        year: state.endDateYear,
                                        month: state.endDateMonth,
                                        day: state.endDateDay
                                    }
                                    : null
                            ),
                            startTime: {
                                hours: state.startTimeHours,
                                minutes: state.startTimeMinutes,
                                seconds: state.startTimeSeconds
                            },
                            endTime: {
                                hours: state.endTimeHours,
                                minutes: state.endTimeMinutes,
                                seconds: state.endTimeSeconds
                            },
                            price: state.price,
                            link: state.link
                        })}

                    />


                    <RequestModule<EventCollaboratorCreateDeleteInput>
                        user={this.props.user}
                        title="Associate Collaborator with Event"
                        path="/event/create/collaborator"
                        requestMethod={RequestMethod.POST}
                        state={{
                            id: "",
                            collaboratorId: ""
                        }}
                        permissionsInfo={[]}
                        permissionsCreate={[Create.EVENT_COLLABORATOR]}
                        permissionsRead={[]}
                        permissionsUpdate={[]}
                        permissionsDelete={[]}
                        items={[
                            {
                                key: "id",
                                type: "text",
                                getter: (thiz: RequestModule<EventCollaboratorCreateDeleteInput>) => thiz.state.id,
                                setter: (v: any, thiz: RequestModule<EventCollaboratorCreateDeleteInput>) => thiz.setState({id: v})
                            },
                            {
                                key: "collaboratorId",
                                type: "text",
                                getter: (thiz: RequestModule<EventCollaboratorCreateDeleteInput>) => thiz.state.collaboratorId,
                                setter: (v: any, thiz: RequestModule<EventCollaboratorCreateDeleteInput>) => thiz.setState({collaboratorId: v})
                            }
                        ]}
                        submit={(state) => eventCreateCollaborator(state)}
                    />

                    <RequestModule<EventCommitteeCreateDeleteInput>
                        user={this.props.user}
                        title="Associate Committee with Event"
                        path="/event/create/committee"
                        requestMethod={RequestMethod.POST}
                        state={{
                            id: "",
                            committeeId: ""
                        }}
                        permissionsInfo={[]}
                        permissionsCreate={[Create.EVENT_COMMITTEE]}
                        permissionsRead={[]}
                        permissionsUpdate={[]}
                        permissionsDelete={[]}
                        items={[
                            {
                                key: "id",
                                type: "text",
                                getter: (thiz: RequestModule<EventCommitteeCreateDeleteInput>) => thiz.state.id,
                                setter: (v: any, thiz: RequestModule<EventCommitteeCreateDeleteInput>) => thiz.setState({id: v})
                            },
                            {
                                key: "committeeId",
                                type: "text",
                                getter: (thiz: RequestModule<EventCommitteeCreateDeleteInput>) => thiz.state.committeeId,
                                setter: (v: any, thiz: RequestModule<EventCommitteeCreateDeleteInput>) => thiz.setState({committeeId: v})
                            }
                        ]}
                        submit={(state) => eventCreateCommittee(state)}
                    />

                    <RequestModule<EventMemberCreateDeleteInput>
                        user={this.props.user}
                        title="Add Member to joined Event"
                        path="/event/create/joinedMember"
                        requestMethod={RequestMethod.POST}
                        state={{
                            id: "",
                            memberId: ""
                        }}
                        permissionsInfo={[]}
                        permissionsCreate={[Create.EVENT_JOINED_MEMBER]}
                        permissionsRead={[]}
                        permissionsUpdate={[]}
                        permissionsDelete={[]}
                        items={[
                            {
                                key: "id",
                                type: "text",
                                getter: (thiz: RequestModule<EventMemberCreateDeleteInput>) => thiz.state.id,
                                setter: (v: any, thiz: RequestModule<EventMemberCreateDeleteInput>) => thiz.setState({id: v})
                            },
                            {
                                key: "memberId",
                                type: "text",
                                getter: (thiz: RequestModule<EventMemberCreateDeleteInput>) => thiz.state.memberId,
                                setter: (v: any, thiz: RequestModule<EventMemberCreateDeleteInput>) => thiz.setState({memberId: v})
                            }
                        ]}
                        submit={(state) => eventCreateJoinedMember(state)}
                    />

                    <RequestModule<EventMemberCreateDeleteInput>
                        user={this.props.user}
                        title="Associate Organizer with Event"
                        path="/event/create/organizer"
                        requestMethod={RequestMethod.POST}
                        state={{
                            id: "",
                            memberId: ""
                        }}
                        permissionsInfo={[]}
                        permissionsCreate={[Create.EVENT_ORGANIZER]}
                        permissionsRead={[]}
                        permissionsUpdate={[]}
                        permissionsDelete={[]}
                        items={[
                            {
                                key: "id",
                                type: "text",
                                getter: (thiz: RequestModule<EventMemberCreateDeleteInput>) => thiz.state.id,
                                setter: (v: any, thiz: RequestModule<EventMemberCreateDeleteInput>) => thiz.setState({id: v})
                            },
                            {
                                key: "memberId",
                                type: "text",
                                getter: (thiz: RequestModule<EventMemberCreateDeleteInput>) => thiz.state.memberId,
                                setter: (v: any, thiz: RequestModule<EventMemberCreateDeleteInput>) => thiz.setState({memberId: v})
                            }
                        ]}
                        submit={(state) => eventCreateOrganizer(state)}
                    />

                    <RequestModule<EventPosterCreateInput>
                        user={this.props.user}
                        title="Create Event Poster"
                        path="/event/create/poster"
                        requestMethod={RequestMethod.POST}
                        state={{
                            id: "",
                            title: "",
                            posterUrl: ""
                        }}
                        permissionsInfo={[]}
                        permissionsCreate={[Create.EVENT_POSTER]}
                        permissionsRead={[]}
                        permissionsUpdate={[]}
                        permissionsDelete={[]}
                        items={[
                            {
                                key: "id",
                                type: "text",
                                getter: (thiz: RequestModule<EventPosterCreateInput>) => thiz.state.id,
                                setter: (v: any, thiz: RequestModule<EventPosterCreateInput>) => thiz.setState({id: v})
                            },
                            {
                                key: "title",
                                type: "text",
                                getter: (thiz: RequestModule<EventPosterCreateInput>) => thiz.state.title,
                                setter: (v: any, thiz: RequestModule<EventPosterCreateInput>) => thiz.setState({title: v})
                            },
                            {
                                key: "posterUrl",
                                type: "text",
                                getter: (thiz: RequestModule<EventPosterCreateInput>) => thiz.state.posterUrl,
                                setter: (v: any, thiz: RequestModule<EventPosterCreateInput>) => thiz.setState({posterUrl: v})
                            }
                        ]}
                        submit={(state) => eventCreatePoster(state)}
                    />
                    {/* Read */}
                    <RequestModule<{}>
                        user={this.props.user}
                        title="Read all Events"
                        path="/event/read"
                        requestMethod={RequestMethod.GET}
                        state={{}}
                        permissionsInfo={[]}
                        permissionsCreate={[]}
                        permissionsRead={[]}
                        permissionsUpdate={[]}
                        permissionsDelete={[]}
                        items={[]}
                        submit={(state) => eventRead()}
                    />
                    {/* Update */}
                    <RequestModule<EventPosterUpdateInput>
                        user={this.props.user}
                        title="Update Event Poster"
                        path="/event/update/poster"
                        requestMethod={RequestMethod.PUT}
                        state={{
                            eventPosterId: "",
                            id: "",
                            title: "",
                            posterUrl: ""
                        }}
                        permissionsInfo={[]}
                        permissionsCreate={[]}
                        permissionsRead={[]}
                        permissionsUpdate={[Update.EVENT_POSTER]}
                        permissionsDelete={[]}
                        items={[
                            {
                                key: "eventPosterId",
                                type: "text",
                                getter: (thiz: RequestModule<EventPosterUpdateInput>) => thiz.state.eventPosterId,
                                setter: (v: any, thiz: RequestModule<EventPosterUpdateInput>) => thiz.setState({eventPosterId: v})
                            },
                            {
                                key: "id",
                                type: "text",
                                getter: (thiz: RequestModule<EventPosterUpdateInput>) => thiz.state.id,
                                setter: (v: any, thiz: RequestModule<EventPosterUpdateInput>) => thiz.setState({id: v})
                            },
                            {
                                key: "title",
                                type: "text",
                                getter: (thiz: RequestModule<EventPosterUpdateInput>) => thiz.state.title,
                                setter: (v: any, thiz: RequestModule<EventPosterUpdateInput>) => thiz.setState({title: v})
                            },
                            {
                                key: "posterUrl",
                                type: "text",
                                getter: (thiz: RequestModule<EventPosterUpdateInput>) => thiz.state.posterUrl,
                                setter: (v: any, thiz: RequestModule<EventPosterUpdateInput>) => thiz.setState({posterUrl: v})
                            }
                        ]}
                        submit={(state) => eventUpdatePoster(state)}
                    />

                    <RequestModule<EventTypeUpdateInput>
                        user={this.props.user}
                        title="Update Event Type"
                        path="/event/update/type"
                        requestMethod={RequestMethod.PUT}
                        state={{
                            eventTypeId: "",
                            name: ""
                        }}
                        permissionsInfo={[]}
                        permissionsCreate={[]}
                        permissionsRead={[]}
                        permissionsUpdate={[Update.EVENT_TYPE]}
                        permissionsDelete={[]}
                        items={[
                            {
                                key: "eventTypeId",
                                type: "text",
                                getter: (thiz: RequestModule<EventTypeUpdateInput>) => thiz.state.eventTypeId,
                                setter: (v: any, thiz: RequestModule<EventTypeUpdateInput>) => thiz.setState({eventTypeId: v})
                            },
                            {
                                key: "name",
                                type: "text",
                                getter: (thiz: RequestModule<EventTypeUpdateInput>) => thiz.state.name,
                                setter: (v: any, thiz: RequestModule<EventTypeUpdateInput>) => thiz.setState({name: v})
                            }
                        ]}
                        submit={(state) => eventUpdateType(state)}
                    />

                </div>


            </div>
    }
}

export interface EventDeleteInput {
    id: string
    title: string
}
