import React from 'react'
import { UserLoggedIn, FileCreateInput, FileCommitteeCreateDeleteInput, FileEventCreateDeleteInput, FileNewsCreateDeleteInput, FileTagCreateDeleteInput, FileTypeCreateInput, FileUpdateInput, FileTypeUpdateInput } from '../../ApiModels'

import '../Admin.css'
import RequestModule from '../RequestModule';
import { fileCreate, fileCreateCommittee, fileCreateEvent, fileCreateNews, fileCreateTag, fileCreateType, fileRead, fileReadById, fileReadType, fileUpdate, fileUpdateType, fileDelete, fileDeleteCommittee, fileDeleteEvent, fileDeleteNews, fileDeleteTag, fileDeleteType } from '../../Requests';
import { Create, RequestMethod, Update, Delete } from '../../Global';

interface FileProps {
    user: UserLoggedIn
}

interface FileState {
}

export default class File extends React.Component<FileProps, FileState> {
    constructor(props: FileProps) {
        super(props)
        this.state = {
        }
    }

    async componentDidMount() {

    }

    public render() {
        return <div>
            {/* Create */}
            <RequestModule<FileCreateInput>
                user={this.props.user}
                title="Create File"
                path="/file/create"
                requestMethod={RequestMethod.POST}
                state={{
                    name: "",
                    description: "",
                    fileTypeId: "",
                    link: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[Create.FILE]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "name",
                        type: "text",
                        getter: (thiz: RequestModule<FileCreateInput>) => thiz.state.name,
                        setter: (v: any, thiz: RequestModule<FileCreateInput>) => thiz.setState({ name: v })
                    },
                    {
                        key: "description",
                        type: "text",
                        getter: (thiz: RequestModule<FileCreateInput>) => thiz.state.description,
                        setter: (v: any, thiz: RequestModule<FileCreateInput>) => thiz.setState({ description: v })
                    },
                    {
                        key: "fileTypeId",
                        type: "text",
                        getter: (thiz: RequestModule<FileCreateInput>) => thiz.state.fileTypeId,
                        setter: (v: any, thiz: RequestModule<FileCreateInput>) => thiz.setState({ fileTypeId: v })
                    },
                    {
                        key: "link",
                        type: "text",
                        getter: (thiz: RequestModule<FileCreateInput>) => thiz.state.link,
                        setter: (v: any, thiz: RequestModule<FileCreateInput>) => thiz.setState({ link: v })
                    }
                ]}
                submit={(state) => fileCreate(state)}
            />

            <RequestModule<FileCommitteeCreateDeleteInput>
                user={this.props.user}
                title="Associate Committee with File"
                path="/file/create/committee"
                requestMethod={RequestMethod.POST}
                state={{
                    id: "",
                    committeeId: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[Create.FILE_COMMITTEE]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<FileCommitteeCreateDeleteInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<FileCommitteeCreateDeleteInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "committeeId",
                        type: "text",
                        getter: (thiz: RequestModule<FileCommitteeCreateDeleteInput>) => thiz.state.committeeId,
                        setter: (v: any, thiz: RequestModule<FileCommitteeCreateDeleteInput>) => thiz.setState({ committeeId: v })
                    }
                ]}
                submit={(state) => fileCreateCommittee(state)}
            />

            <RequestModule<FileEventCreateDeleteInput>
                user={this.props.user}
                title="Associate Event with File"
                path="/file/create/event"
                requestMethod={RequestMethod.POST}
                state={{
                    id: "",
                    eventId: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[Create.FILE_EVENT]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<FileEventCreateDeleteInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<FileEventCreateDeleteInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "eventId",
                        type: "text",
                        getter: (thiz: RequestModule<FileEventCreateDeleteInput>) => thiz.state.eventId,
                        setter: (v: any, thiz: RequestModule<FileEventCreateDeleteInput>) => thiz.setState({ eventId: v })
                    }
                ]}
                submit={(state) => fileCreateEvent(state)}
            />

            <RequestModule<FileNewsCreateDeleteInput>
                user={this.props.user}
                title="Associate News with File"
                path="/file/create/news"
                requestMethod={RequestMethod.POST}
                state={{
                    id: "",
                    newsId: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[Create.FILE_NEWS]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<FileNewsCreateDeleteInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<FileNewsCreateDeleteInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "newsId",
                        type: "text",
                        getter: (thiz: RequestModule<FileNewsCreateDeleteInput>) => thiz.state.newsId,
                        setter: (v: any, thiz: RequestModule<FileNewsCreateDeleteInput>) => thiz.setState({ newsId: v })
                    }
                ]}
                submit={(state) => fileCreateNews(state)}
            />

            <RequestModule<FileTagCreateDeleteInput>
                user={this.props.user}
                title="Associate File Tag with File"
                path="/file/create/tag"
                requestMethod={RequestMethod.POST}
                state={{
                    id: "",
                    tag: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[Create.FILE_TAG]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<FileTagCreateDeleteInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<FileTagCreateDeleteInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "tag",
                        type: "text",
                        getter: (thiz: RequestModule<FileTagCreateDeleteInput>) => thiz.state.tag,
                        setter: (v: any, thiz: RequestModule<FileTagCreateDeleteInput>) => thiz.setState({ tag: v })
                    }
                ]}
                submit={(state) => fileCreateTag(state)}
            />

            <RequestModule<FileTypeCreateInput>
                user={this.props.user}
                title="Create File Type"
                path="/file/create/type"
                requestMethod={RequestMethod.POST}
                state={{
                    type: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[Create.FILE_TYPE]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "type",
                        type: "text",
                        getter: (thiz: RequestModule<FileTypeCreateInput>) => thiz.state.type,
                        setter: (v: any, thiz: RequestModule<FileTypeCreateInput>) => thiz.setState({ type: v })
                    }
                ]}
                submit={(state) => fileCreateType(state)}
            />

            {/* Read */}
            <RequestModule<{}>
                user={this.props.user}
                title="Read all Files"
                path="/file/read"
                requestMethod={RequestMethod.GET}
                state={{}}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[]}
                submit={(state) => fileRead()}
            />

            <RequestModule<Input>
                user={this.props.user}
                title="Read File by id"
                path="/file/read/{id}"
                requestMethod={RequestMethod.GET}
                state={{
                    id: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<Input>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<Input>) => thiz.setState({ id: v })
                    }
                ]}
                submit={(state) => fileReadById(state.id)}
            />

            <RequestModule<{}>
                user={this.props.user}
                title="Read all Files Types"
                path="/file/read/type"
                requestMethod={RequestMethod.GET}
                state={{}}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[]}
                submit={(state) => fileReadType()}
            />

            {/* Update */}
            <RequestModule<FileUpdateInput>
                user={this.props.user}
                title="Update File"
                path="/file/update"
                requestMethod={RequestMethod.PUT}
                state={{
                    id: "",
                    name: "",
                    description: "",
                    fileTypeId: "",
                    link: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[Update.FILE]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<FileUpdateInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<FileUpdateInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "name",
                        type: "text",
                        getter: (thiz: RequestModule<FileUpdateInput>) => thiz.state.name,
                        setter: (v: any, thiz: RequestModule<FileUpdateInput>) => thiz.setState({ name: v })
                    },
                    {
                        key: "description",
                        type: "text",
                        getter: (thiz: RequestModule<FileUpdateInput>) => thiz.state.description,
                        setter: (v: any, thiz: RequestModule<FileUpdateInput>) => thiz.setState({ description: v })
                    },
                    {
                        key: "fileTypeId",
                        type: "text",
                        getter: (thiz: RequestModule<FileUpdateInput>) => thiz.state.fileTypeId,
                        setter: (v: any, thiz: RequestModule<FileUpdateInput>) => thiz.setState({ fileTypeId: v })
                    },
                    {
                        key: "link",
                        type: "text",
                        getter: (thiz: RequestModule<FileUpdateInput>) => thiz.state.link,
                        setter: (v: any, thiz: RequestModule<FileUpdateInput>) => thiz.setState({ link: v })
                    }
                ]}
                submit={(state) => fileUpdate(state)}
            />

            <RequestModule<FileTypeUpdateInput>
                user={this.props.user}
                title="Update File Type"
                path="/file/update/type"
                requestMethod={RequestMethod.PUT}
                state={{
                    fileTypeId: "",
                    type: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[Update.FILE_TYPE]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "fileTypeId",
                        type: "text",
                        getter: (thiz: RequestModule<FileTypeUpdateInput>) => thiz.state.fileTypeId,
                        setter: (v: any, thiz: RequestModule<FileTypeUpdateInput>) => thiz.setState({ fileTypeId: v })
                    },
                    {
                        key: "type",
                        type: "text",
                        getter: (thiz: RequestModule<FileTypeUpdateInput>) => thiz.state.type,
                        setter: (v: any, thiz: RequestModule<FileTypeUpdateInput>) => thiz.setState({ type: v })
                    }
                ]}
                submit={(state) => fileUpdateType(state)}
            />

            {/* Delete */}
            <RequestModule<Input>
                user={this.props.user}
                title="Delete File"
                path="/file/delete"
                requestMethod={RequestMethod.DELETE}
                state={{
                    id: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[Delete.FILE]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<Input>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<Input>) => thiz.setState({ id: v })
                    }
                ]}
                submit={(state) => fileDelete(state.id)}
            />

            <RequestModule<FileCommitteeCreateDeleteInput>
                user={this.props.user}
                title="Delete Committee association with File"
                path="/file/delete/committee"
                requestMethod={RequestMethod.DELETE}
                state={{
                    id: "",
                    committeeId: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[Delete.FILE_COMMITTEE]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<FileCommitteeCreateDeleteInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<FileCommitteeCreateDeleteInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "committeeId",
                        type: "text",
                        getter: (thiz: RequestModule<FileCommitteeCreateDeleteInput>) => thiz.state.committeeId,
                        setter: (v: any, thiz: RequestModule<FileCommitteeCreateDeleteInput>) => thiz.setState({ committeeId: v })
                    }
                ]}
                submit={(state) => fileDeleteCommittee(state)}
            />

            <RequestModule<FileEventCreateDeleteInput>
                user={this.props.user}
                title="Delete Event association with File"
                path="/file/delete/event"
                requestMethod={RequestMethod.DELETE}
                state={{
                    id: "",
                    eventId: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[Delete.FILE_EVENT]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<FileEventCreateDeleteInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<FileEventCreateDeleteInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "eventId",
                        type: "text",
                        getter: (thiz: RequestModule<FileEventCreateDeleteInput>) => thiz.state.eventId,
                        setter: (v: any, thiz: RequestModule<FileEventCreateDeleteInput>) => thiz.setState({ eventId: v })
                    }
                ]}
                submit={(state) => fileDeleteEvent(state)}
            />

            <RequestModule<FileNewsCreateDeleteInput>
                user={this.props.user}
                title="Delete News association with File"
                path="/file/delete/news"
                requestMethod={RequestMethod.DELETE}
                state={{
                    id: "",
                    newsId: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[Delete.FILE_NEWS]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<FileNewsCreateDeleteInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<FileNewsCreateDeleteInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "newsId",
                        type: "text",
                        getter: (thiz: RequestModule<FileNewsCreateDeleteInput>) => thiz.state.newsId,
                        setter: (v: any, thiz: RequestModule<FileNewsCreateDeleteInput>) => thiz.setState({ newsId: v })
                    }
                ]}
                submit={(state) => fileDeleteNews(state)}
            />

            <RequestModule<FileTagCreateDeleteInput>
                user={this.props.user}
                title="Delete File Tag"
                path="/file/delete/tag"
                requestMethod={RequestMethod.DELETE}
                state={{
                    id: "",
                    tag: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[Delete.FILE_TAG]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<FileTagCreateDeleteInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<FileTagCreateDeleteInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "tag",
                        type: "text",
                        getter: (thiz: RequestModule<FileTagCreateDeleteInput>) => thiz.state.tag,
                        setter: (v: any, thiz: RequestModule<FileTagCreateDeleteInput>) => thiz.setState({ tag: v })
                    }
                ]}
                submit={(state) => fileDeleteTag(state)}
            />

            <RequestModule<Input>
                user={this.props.user}
                title="Delete File Type"
                path="/file/delete/type"
                requestMethod={RequestMethod.DELETE}
                state={{
                    id: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[Delete.FILE_TYPE]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<Input>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<Input>) => thiz.setState({ id: v })
                    }
                ]}
                submit={(state) => fileDeleteType(state.id)}
            />
        </div>
    }
}

interface Input {
    id: string
}