import React from 'react'
import {Link} from 'react-router-dom'
import './Header.css'

import {ASSETS_URL} from '../Global'
import {UserLoggedIn} from '../ApiModels';
import {hasAccessToAdminPanel, hasAccessToContentPanel} from '../Auth';

interface HeaderProps {
    user: UserLoggedIn
}

interface HeaderState {
    isMobile: boolean
    isMobileSmall: boolean
    showDropdown: boolean
}

export default class Header extends React.Component<HeaderProps, HeaderState> {
    constructor(props: HeaderProps) {
        super(props)
        this.state = {
            isMobile: window.innerWidth <= 1060,
            isMobileSmall: window.innerWidth <= 820,
            showDropdown: false
        }

        window.addEventListener('resize', () => {
            this.setState({
                isMobile: window.innerWidth <= 1060,
                isMobileSmall: window.innerWidth <= 820,
                showDropdown: false
            })
        })
    }

    public render() {
        return (
            <div>
                <div className="header-container">
                    <Link
                        to="/"
                        onClick={() => {
                            this.setState({showDropdown: false})
                        }}
                    >
                        <div className="header-left-container">
                            <img
                                className="header-logo"
                                src={ASSETS_URL + "/logo/white_transparent.png"}
                                alt="CSAR Logo"/>
                            {!this.state.isMobile
                                ? (<p className="header-text">Computer Science Asssociation Rotterdam</p>)
                                : null
                            }
                        </div>
                    </Link>

                    {!this.state.isMobileSmall
                        ? (this.props.user.loggedIn
                                ? (<div className="header-right-container">
                                    {hasAccessToAdminPanel(this.props.user)
                                        ? <div className="header-right-container">
                                            <div>
                                                <Link to="/admin">
                                                    <div className="header-button header-login-button">
                                                        <p className="header-text">Admin</p>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                        : undefined
                                    }
                                    {hasAccessToContentPanel(this.props.user)
                                        ? <div className="header-right-container">
                                            <div>
                                                <Link to="/content">
                                                    <div className="header-button header-login-button">
                                                        <p className="header-text">Content</p>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                        : undefined}
                                    <Link to="/logout">
                                        <div className="header-button header-login-button">
                                            <p className="header-text">Uitloggen</p>
                                        </div>
                                    </Link>
                                    <Link to="/profile">
                                        <div className="header-button header-register-button">
                                            <p className="header-text header-register-text">Mijn profiel</p>
                                        </div>
                                    </Link>
                                </div>)
                                : (<div className="header-right-container">
                                    <Link to="/login">
                                        <div className="header-button header-login-button">
                                            <p className="header-text">Inloggen</p>
                                        </div>
                                    </Link>
                                    <Link to="/register">
                                        <div className="header-button header-register-button">
                                            <p className="header-text header-register-text">Aanmelden</p>
                                        </div>
                                    </Link>
                                </div>)
                        )
                        : (<div className="header-right-container">
                            <div
                                className={this.state.showDropdown
                                    ? "header-button header-dropdown-button-active"
                                    : "header-button header-dropdown-button"
                                }
                                onClick={() => this.setState({showDropdown: !this.state.showDropdown})}
                            >
                                <i className="fas fa-bars"></i>
                            </div>
                        </div>)}
                </div>
                <div className="clr"></div>

                {this.state.showDropdown
                    ? (<div className="header-dropdown-background">
                        {this.props.user.loggedIn ? (
                            <div>
                                {hasAccessToAdminPanel(this.props.user)
                                    ? <Link to="/admin">
                                        <div className="header-button header-login-button header-dropdown-item">
                                            <p className="header-text">Admin</p>
                                        </div>
                                    </Link>
                                    : undefined
                                }
                                {hasAccessToContentPanel(this.props.user)
                                    ? <Link to="/content">
                                        <div className="header-button header-login-button header-dropdown-item">
                                            <p className="header-text">Content</p>
                                        </div>
                                    </Link>
                                    : undefined
                                }
                                <Link
                                    to="/logout"
                                    onClick={() => {
                                        this.setState({showDropdown: false})
                                    }}
                                >
                                    <div className="header-button header-login-button header-dropdown-item">
                                        <p className="header-text">Uitloggen</p>
                                    </div>
                                </Link>
                                <Link
                                    to="/profile"
                                    onClick={() => {
                                        this.setState({showDropdown: false})
                                    }}
                                >
                                    <div className="header-button header-register-button header-dropdown-item">
                                        <p className="header-text header-register-text">Mijn profiel</p>
                                    </div>
                                </Link>
                            </div>
                        ) : (
                            <div>
                                <Link
                                    to="/login"
                                    onClick={() => {
                                        this.setState({showDropdown: false})
                                    }}
                                >
                                    <div className="header-button header-login-button header-dropdown-item">
                                        <p className="header-text">Inloggen</p>
                                    </div>
                                </Link>
                                <Link
                                    to="/register"
                                    onClick={() => {
                                        this.setState({showDropdown: false})
                                    }}
                                >
                                    <div className="header-button header-register-button header-dropdown-item">
                                        <p className="header-text header-register-text">Aanmelden</p>
                                    </div>
                                </Link>
                            </div>
                        )
                        }
                    </div>)
                    : null
                }
            </div>
        )
    }
}