import React from 'react'
import './Footer.css'
import { NavLink } from 'react-router-dom';
import '../../assets/css/all.min.css';

export default class Footer extends React.Component {

    render() {
        return (
            <div>
                <div className="footer-spacer"/>
                <div className="footer-container">
                    <div className="footer-left-container">
                        <div className="footer-linkbox">
                            {/*TODO: Add links to pages when pages are available*/}
                            <div className='footer-column'>
                                <NavLink to="/" className='footer-column'>
                                    <i className="fas fa-home"></i>
                                    <p className="footer-text-short link">Home</p>
                                    <p className="footer-text link">Home</p>
                                </NavLink>
                            </div>
                            <hr className='footer-column-separator'/>
                            <div className='footer-column'>
                                <NavLink to="/contact" className='footer-column'>
                                    <i className="fas fa-envelope"></i>
                                    <p className="footer-text-short link">Contact</p>
                                    <p className="footer-text link">Contact</p>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-under-container">
                    <div className="footer-left-container">
                        <p className="footer-text-short under">&copy; 2020 CSAR</p>
                        <p className="footer-text under">&copy; 2020 Computer Science Association Rotterdam. Alle rechten
                            voorbehouden</p>
                        <p className="footer-text under"> | </p>
                        <p className="footer-text under">
                            <a href='https://documenten.csarotterdam.nl/Privacyverklaring.pdf' className="link" target='_blank' rel="noopener noreferrer">
                                Privacyverklaring
                            </a>
                        </p>
                        <p className="footer-text under"> | </p>
                        <p className="footer-text under link"> Algemene Voorwaarden </p>

                    </div>
                    <div className="footer-right-container">
                        <p className="footer-text-short under">Follow us on:</p>
                        <p className="footer-text under">Follow us on:</p>
                        <a href='https://www.facebook.com/CSARotterdam-103530681098231' className="under">
                            {/*<img className='footer-under-image' src={facebook}/>*/}
                            <i className="fab fa-facebook-square"></i>

                        </a>
                        &nbsp;
                        <a href='https://www.linkedin.com/company/csarotterdam' className="under">
                            {/*<img className='footer-under-image' src={linkedin}/>*/}
                            <i className="fab fa-linkedin"></i>
                        </a>
                        &nbsp;
                        <a href='https://www.instagram.com/csarotterdam/' className="under">
                            {/*<img className='footer-under-image' src={instagram}/>*/}
                            <i className="fab fa-instagram-square"></i>
                        </a>
                        &nbsp;
                        <p className="footer-text-short under"> | </p>
                        <p className="footer-text under"> | </p>
                        <p className="footer-text-short under">KvK 74532871</p>
                        <p className="footer-text under">KvK 74532871</p>
                        <p className="footer-text-short under"> | </p>
                        <p className="footer-text under"> | </p>
                        <p className="footer-text-short under">Wijnhaven 107</p>
                        <p className="footer-text under">Wijnhaven 107 3011WN Rotterdam</p>
                    </div>
                </div>
            </div>
        )
    }
}