import React from 'react'
import { Redirect } from 'react-router'

import { authLogoutRequest } from '../../Requests'
import { clearAuth, getToken } from '../../Persistence'

interface LogoutProps {
    updateAuth: () => void
}

export default class Logout extends React.Component<LogoutProps, {}> {

    async componentDidMount() {
        let token = getToken()
        if (token !== null) {
            await authLogoutRequest(token)
        }

        clearAuth()
        this.props.updateAuth()
    }

    public render() {
        return (
            <Redirect to="/" />
        )
    }
}