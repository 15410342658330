import React from 'react'
import { UserLoggedIn } from '../../ApiModels'

import '../Admin.css'
import RequestModule from '../RequestModule';
import { loggingReadErrors, loggingReadByReferenceId, loggingDeleteByReferenceId } from '../../Requests';
import { RequestMethod, Info } from '../../Global';

interface LoggingProps {
    user: UserLoggedIn
}

interface LoggingState {
}

export default class Logging extends React.Component<LoggingProps, LoggingState> {
    constructor(props: LoggingProps) {
        super(props)
        this.state = {
        }
    }

    async componentDidMount() {

    }

    public render() {
        return <div>
            {/* Read */}
            <RequestModule<{}>
                user={this.props.user}
                title="Read all log errors"
                path="/log/error"
                requestMethod={RequestMethod.POST}
                state={{}}
                permissionsInfo={[Info.LOG]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[]}
                submit={(state) => loggingReadErrors()}
            />

            <RequestModule<LoggingInputState>
                user={this.props.user}
                title="Read all logs by reference id"
                path="/log/reference"
                requestMethod={RequestMethod.POST}
                state={{
                    input: ""
                }}
                permissionsInfo={[Info.LOG]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "referenceId",
                        type: "text",
                        getter: (thiz: RequestModule<LoggingInputState>) => thiz.state.input,
                        setter: (v: any, thiz: RequestModule<LoggingInputState>) => thiz.setState({ input: v })
                    }
                ]}
                submit={(state) => loggingReadByReferenceId(state.input)}
            />

            {/* Delete */}
            <RequestModule<LoggingInputState>
                user={this.props.user}
                title="Delete all logs by reference id"
                path="/log/delete"
                requestMethod={RequestMethod.DELETE}
                state={{
                    input: ""
                }}
                permissionsInfo={[Info.LOG]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "referenceId",
                        type: "text",
                        getter: (thiz: RequestModule<LoggingInputState>) => thiz.state.input,
                        setter: (v: any, thiz: RequestModule<LoggingInputState>) => thiz.setState({ input: v })
                    }
                ]}
                submit={(state) => loggingDeleteByReferenceId(state.input)}
            />
        </div>
    }
}

interface LoggingInputState {
    input: string
}