import { UserLoggedIn } from "./ApiModels";

export function hasAccessToAdminPanel(user: UserLoggedIn) {
    return user.loggedIn && user.role !== null &&
        ["ADMIN"].includes(user.role.role)
}

export function hasAccessToContentPanel(user: UserLoggedIn) {
    return user.loggedIn && user.role !== null &&
        ["CURATOR", "MODERATOR", "ADMIN"].includes(user.role.role)
}