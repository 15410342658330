import React from 'react'
import '../../Form.css'

import { authLoginRequest } from '../../Requests'
import { saveAuth, checkAuthIsValid } from '../../Persistence'
import { Redirect } from 'react-router'
import { isMessage } from '../../Helper'
import { UserLoggedIn } from '../../ApiModels';

interface LoginProps {
    updateAuth: () => void
}

interface LoginState {
    mail: string
    password: string
    loggingIn: boolean
    user: UserLoggedIn
    errorMessage: string | null
}

export default class Login extends React.Component<LoginProps, LoginState> {
    constructor(props: any) {
        super(props)
        this.state = {
            mail: "",
            password: "",
            loggingIn: false,
            user: {
                loggedIn: false,
                role: null
            },
            errorMessage: null
        };

        this.handleSubmit = this.handleSubmit.bind(this)
    }

    async componentDidMount() {
        this.setState({ user: await checkAuthIsValid() })
    }

    private async handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()

        if (!this.state.loggingIn) {
            this.setState({
                loggingIn: true,
                errorMessage: null
            })

            const authLoginResponse = await authLoginRequest(this.state.mail, this.state.password)
            if (!isMessage(authLoginResponse)) {
                if (authLoginResponse.successful) {
                    saveAuth(authLoginResponse)
                    this.setState({
                        loggingIn: false,
                        user: {
                            loggedIn: true,
                            role: authLoginResponse.role
                        }
                    })
                    this.props.updateAuth()
                } else {
                    this.setState({
                        loggingIn: false,
                        errorMessage: authLoginResponse.message
                    })
                }
            } else {
                this.setState({
                    loggingIn: false,
                    errorMessage: authLoginResponse.message
                })
            }
        }
    }

    public render() {
        return this.state.user.loggedIn ? (
            // TODO: change to just "/" for home later
            <Redirect to="/profile" />
        ) : (
                <div className="form-container">
                    <div className="form-box">
                        <form
                            className="form"
                            method="post"
                            onSubmit={this.handleSubmit}>
                            <input
                                className="form-input"
                                name="mail"
                                type="email"
                                placeholder="email"
                                autoComplete="off"
                                value={this.state.mail}
                                onChange={event => this.setState({ mail: event.target.value })}
                                readOnly={this.state.loggingIn} />
                            <input
                                className="form-input"
                                name="password"
                                type="password"
                                placeholder="password"
                                autoComplete="off"
                                value={this.state.password}
                                onChange={event => this.setState({ password: event.target.value })}
                                readOnly={this.state.loggingIn} />
                            <div className="form-submit-error-text">
                                {this.state.errorMessage !== null ? this.state.errorMessage : ""}
                            </div>
                            <input
                                className="form-input form-submit-button"
                                type="submit"
                                value={!this.state.loggingIn ? "Login" : "Inloggen..."}
                                readOnly={this.state.loggingIn} />
                        </form>
                    </div>
                </div>
            )
    }
}