import React from 'react'
import { Redirect } from 'react-router-dom'
import { UserLoggedIn } from '../ApiModels'

import './Admin.css'

import Member from './components/Member'
import Other from './components/Other'
import MemberTemplate from './components/MemberTemplate';
import Logging from './components/Logging';
import MemberAuthRoles from './components/MemberAuthRoles';
import Statistics from './components/Statistics';
import MemberCommittee from './components/MemberCommittee';

interface AdminProps {
    user: UserLoggedIn
}

interface AdminState {
    loading: boolean
    menu: "member" | "member committee" | "member template" | "logging" | "auth roles" | "statistics" | "other"
}

export default class Admin extends React.Component<AdminProps, AdminState> {
    constructor(props: AdminProps) {
        super(props)
        this.state = {
            loading: true,
            menu: "member"
        }

        this.content = this.content.bind(this)
    }

    async componentDidMount() {
        if (this.props.user.loggedIn) {
            this.setState({
                loading: false
            })
        }
    }

    content() {
        switch (this.state.menu) {
            case "member":
                return <Member user={this.props.user} />
            case "member committee":
                return <MemberCommittee user={this.props.user} />
            case "member template":
                return <MemberTemplate user={this.props.user} />
            case "logging":
                return <Logging user={this.props.user} />
            case "auth roles":
                return <MemberAuthRoles user={this.props.user} />
            case "statistics":
                return <Statistics user={this.props.user} />
            case "other":
                return <Other user={this.props.user} />

            default:
                return "Page not found..."
        }
    }


    public render() {
        return !this.props.user.loggedIn ? (
            <Redirect to="/login" />
        ) :
            <div className="admin-container">
                <div className="admin-box-static">
                    {this.state.loading
                        ? undefined
                        : (
                            <div>
                                <h1>Admin Panel</h1>
                                <br/>
                                {/* Header */}
                                <div className="menu">
                                    <div className={this.state.menu === "member"
                                        ? "menu-block menu-block-selected"
                                        : "menu-block"
                                    } onClick={() => this.setState({ menu: "member" })}>
                                        <p className="menu-block-content">Member</p>
                                    </div>
                                    <div className={this.state.menu === "member committee"
                                        ? "menu-block menu-block-selected"
                                        : "menu-block"
                                    } onClick={() => this.setState({ menu: "member committee" })}>
                                        <p className="menu-block-content">Member Committee</p>
                                    </div>
                                    <div className={this.state.menu === "member template"
                                        ? "menu-block menu-block-selected"
                                        : "menu-block"
                                    } onClick={() => this.setState({ menu: "member template" })}>
                                        <p className="menu-block-content">Member Template</p>
                                    </div>
                                    <div className={this.state.menu === "logging"
                                        ? "menu-block menu-block-selected"
                                        : "menu-block"
                                    } onClick={() => this.setState({ menu: "logging" })}>
                                        <p className="menu-block-content">Logging</p>
                                    </div>
                                    <div className={this.state.menu === "auth roles"
                                        ? "menu-block menu-block-selected"
                                        : "menu-block"
                                    } onClick={() => this.setState({ menu: "auth roles" })}>
                                        <p className="menu-block-content">Auth Roles</p>
                                    </div>
                                    <div className={this.state.menu === "statistics"
                                        ? "menu-block menu-block-selected"
                                        : "menu-block"
                                    } onClick={() => this.setState({ menu: "statistics" })}>
                                        <p className="menu-block-content">Statistics</p>
                                    </div>
                                    <div className={this.state.menu === "other"
                                        ? "menu-block menu-block-selected"
                                        : "menu-block"
                                    } onClick={() => this.setState({ menu: "other" })}>
                                        <p className="menu-block-content">Other...</p>
                                    </div>
                                </div>

                                {/* Content */}
                                <br />
                                {this.content()}
                            </div>
                        )
                    }
                </div>
            </div>
    }
}