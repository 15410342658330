import React from 'react'
import { UserLoggedIn, EventCollaboratorCreateDeleteInput, EventCommitteeCreateDeleteInput, EventMemberCreateDeleteInput, EventPosterCreateInput, EventTagCreateDeleteInput, EventTypeCreateInput, EventPosterUpdateInput, EventTypeUpdateInput } from '../../ApiModels'

import '../Admin.css'
import RequestModule from '../RequestModule';
import { eventCreate, eventCreateCollaborator, eventCreateCommittee, eventCreateJoinedMember, eventCreateOrganizer, eventCreatePoster, eventCreateTag, eventCreateType, eventRead, eventReadById, eventUpdatePoster, eventUpdateType, eventDelete, eventDeleteCollaborator, eventDeleteCommittee, eventDeleteJoinedMember, eventDeleteOrganizer, eventDeletePoster, eventDeleteTag, eventDeleteType, eventReadType } from '../../Requests';
import { Create, RequestMethod, Update, Delete } from '../../Global';
import { toNumber, toNumberOrNull } from '../../Helper';

interface EventProps {
    user: UserLoggedIn
}

interface EventState {
}

export default class Event extends React.Component<EventProps, EventState> {
    constructor(props: EventProps) {
        super(props)
        this.state = {
        }
    }

    async componentDidMount() {

    }

    public render() {
        return <div>
            {/* Create */}
            <RequestModule<EventCreateInput>
                user={this.props.user}
                title="Create Event"
                path="/event/create"
                requestMethod={RequestMethod.POST}
                state={{
                    title: "",
                    subTitle: "",
                    shortDescription: "",
                    longDescription: "",
                    imgUrl: "",
                    content: "",
                    eventTypeId: "",
                    subject: "",

                    streetName: "",
                    houseNumber: 0,
                    houseNumberLetter: null,
                    houseNumberAddition: null,
                    postalCode: "",
                    city: "",

                    addressRoom: null,
                    schoolYear: 0,

                    startDateYear: 0,
                    startDateMonth: 0,
                    startDateDay: 0,

                    endDateYear: null,
                    endDateMonth: null,
                    endDateDay: null,

                    startTimeHours: 0,
                    startTimeMinutes: 0,
                    startTimeSeconds: 0,

                    endTimeHours: 0,
                    endTimeMinutes: 0,
                    endTimeSeconds: 0,

                    price: 0.0,
                    link: null
                }}
                permissionsInfo={[]}
                permissionsCreate={[Create.EVENT]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "title",
                        type: "text",
                        getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.title,
                        setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({ title: v })
                    },
                    {
                        key: "subTitle",
                        type: "text",
                        getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.subTitle,
                        setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({ subTitle: v })
                    },
                    {
                        key: "shortDescription",
                        type: "text",
                        getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.shortDescription,
                        setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({ shortDescription: v })
                    },
                    {
                        key: "longDescription",
                        type: "text",
                        getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.longDescription,
                        setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({ longDescription: v })
                    },
                    {
                        key: "imgUrl",
                        type: "text",
                        getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.imgUrl,
                        setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({ imgUrl: v })
                    },
                    {
                        key: "content",
                        type: "text",
                        getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.content,
                        setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({ content: v })
                    },
                    {
                        key: "eventTypeId",
                        type: "text",
                        getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.eventTypeId,
                        setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({ eventTypeId: v })
                    },
                    {
                        key: "subject",
                        type: "text",
                        getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.subject,
                        setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({ subject: v })
                    },

                    {
                        key: "streetName",
                        type: "text",
                        getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.streetName,
                        setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({ streetName: v })
                    },
                    {
                        key: "houseNumber",
                        type: "number",
                        getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.houseNumber,
                        setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({ houseNumber: toNumber(parseInt(v)) })
                    },
                    {
                        key: "houseNumberLetter",
                        type: "text",
                        getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.houseNumberLetter,
                        setter: (v: string, thiz: RequestModule<EventCreateInput>) => thiz.setState({ houseNumberLetter: (v.replace(" ", "").length > 0) ? v : null })
                    },
                    {
                        key: "houseNumberAddition",
                        type: "text",
                        getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.houseNumberAddition,
                        setter: (v: string, thiz: RequestModule<EventCreateInput>) => thiz.setState({ houseNumberAddition: (v.replace(" ", "").length > 0) ? v : null })
                    },
                    {
                        key: "postalCode",
                        type: "text",
                        getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.postalCode,
                        setter: (v: string, thiz: RequestModule<EventCreateInput>) => thiz.setState({ postalCode: v })
                    },
                    {
                        key: "city",
                        type: "text",
                        getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.city,
                        setter: (v: string, thiz: RequestModule<EventCreateInput>) => thiz.setState({ city: v })
                    },

                    {
                        key: "addressRoom",
                        type: "text",
                        getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.addressRoom,
                        setter: (v: string, thiz: RequestModule<EventCreateInput>) => thiz.setState({ addressRoom: (v.replace(" ", "").length > 0) ? v : null })
                    },
                    {
                        key: "schoolYear",
                        type: "number",
                        getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.schoolYear,
                        setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({ schoolYear: toNumber(parseInt(v)) })
                    },

                    {
                        key: "startDateYear",
                        type: "number",
                        getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.startDateYear,
                        setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({ startDateYear: toNumber(parseInt(v)) })
                    },
                    {
                        key: "startDateMonth",
                        type: "number",
                        getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.startDateMonth,
                        setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({ startDateMonth: toNumber(parseInt(v)) })
                    },
                    {
                        key: "startDateDay",
                        type: "number",
                        getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.startDateDay,
                        setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({ startDateDay: toNumber(parseInt(v)) })
                    },

                    {
                        key: "endDateYear",
                        type: "number",
                        getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.endDateYear,
                        setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({ endDateYear: toNumberOrNull(parseInt(v)) })
                    },
                    {
                        key: "endDateMonth",
                        type: "number",
                        getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.endDateMonth,
                        setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({ endDateMonth: toNumberOrNull(parseInt(v)) })
                    },
                    {
                        key: "endDateDay",
                        type: "number",
                        getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.endDateDay,
                        setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({ endDateDay: toNumberOrNull(parseInt(v)) })
                    },

                    {
                        key: "startTimeHours",
                        type: "number",
                        getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.startTimeHours,
                        setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({ startTimeHours: toNumber(parseInt(v)) })
                    },
                    {
                        key: "startTimeMinutes",
                        type: "number",
                        getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.startTimeMinutes,
                        setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({ startTimeMinutes: toNumber(parseInt(v)) })
                    },
                    {
                        key: "startTimeSeconds",
                        type: "number",
                        getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.startTimeSeconds,
                        setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({ startTimeSeconds: toNumber(parseInt(v)) })
                    },

                    {
                        key: "endTimeHours",
                        type: "number",
                        getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.endTimeHours,
                        setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({ endTimeHours: toNumber(parseInt(v)) })
                    },
                    {
                        key: "endTimeMinutes",
                        type: "number",
                        getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.endTimeMinutes,
                        setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({ endTimeMinutes: toNumber(parseInt(v)) })
                    },
                    {
                        key: "endTimeSeconds",
                        type: "number",
                        getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.endTimeSeconds,
                        setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({ endTimeSeconds: toNumber(parseInt(v)) })
                    },

                    {
                        key: "price",
                        type: "number",
                        getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.price,
                        setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({ price: toNumber(parseInt(v)) })
                    },
                    {
                        key: "link",
                        type: "text",
                        getter: (thiz: RequestModule<EventCreateInput>) => thiz.state.link,
                        setter: (v: any, thiz: RequestModule<EventCreateInput>) => thiz.setState({ link: (v.replace(" ", "").length > 0 ? v : null) })
                    }
                ]}
                submit={(state) => eventCreate({
                    title: state.title,
                    subTitle: state.subTitle,
                    shortDescription: state.shortDescription,
                    longDescription: state.longDescription,
                    imgUrl: state.imgUrl,
                    content: state.content,
                    eventTypeId: state.eventTypeId,
                    subject: state.subject,
                    address: {
                        streetName: state.streetName,
                        houseNumber: state.houseNumber,
                        houseNumberLetter: state.houseNumberLetter,
                        houseNumberAddition: state.houseNumberAddition,
                        postalCode: state.postalCode,
                        city: state.city
                    },
                    addressRoom: state.addressRoom,
                    schoolYear: state.schoolYear,
                    startDate: {
                        year: state.startDateYear,
                        month: state.startDateMonth,
                        day: state.startDateDay
                    },
                    endDate: (state.endDateYear !== null && state.endDateMonth !== null && state.endDateDay !== null
                        ? {
                            year: state.endDateYear,
                            month: state.endDateMonth,
                            day: state.endDateDay
                        }
                        : null
                    ),
                    startTime: {
                        hours: state.startTimeHours,
                        minutes: state.startTimeMinutes,
                        seconds: state.startTimeSeconds
                    },
                    endTime: {
                        hours: state.endTimeHours,
                        minutes: state.endTimeMinutes,
                        seconds: state.endTimeSeconds
                    },
                    price: state.price,
                    link: state.link
                })}
            />

            <RequestModule<EventCollaboratorCreateDeleteInput>
                user={this.props.user}
                title="Associate Collaborator with Event"
                path="/event/create/collaborator"
                requestMethod={RequestMethod.POST}
                state={{
                    id: "",
                    collaboratorId: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[Create.EVENT_COLLABORATOR]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<EventCollaboratorCreateDeleteInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<EventCollaboratorCreateDeleteInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "collaboratorId",
                        type: "text",
                        getter: (thiz: RequestModule<EventCollaboratorCreateDeleteInput>) => thiz.state.collaboratorId,
                        setter: (v: any, thiz: RequestModule<EventCollaboratorCreateDeleteInput>) => thiz.setState({ collaboratorId: v })
                    }
                ]}
                submit={(state) => eventCreateCollaborator(state)}
            />

            <RequestModule<EventCommitteeCreateDeleteInput>
                user={this.props.user}
                title="Associate Committee with Event"
                path="/event/create/committee"
                requestMethod={RequestMethod.POST}
                state={{
                    id: "",
                    committeeId: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[Create.EVENT_COMMITTEE]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<EventCommitteeCreateDeleteInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<EventCommitteeCreateDeleteInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "committeeId",
                        type: "text",
                        getter: (thiz: RequestModule<EventCommitteeCreateDeleteInput>) => thiz.state.committeeId,
                        setter: (v: any, thiz: RequestModule<EventCommitteeCreateDeleteInput>) => thiz.setState({ committeeId: v })
                    }
                ]}
                submit={(state) => eventCreateCommittee(state)}
            />

            <RequestModule<EventMemberCreateDeleteInput>
                user={this.props.user}
                title="Add Member to joined Event"
                path="/event/create/joinedMember"
                requestMethod={RequestMethod.POST}
                state={{
                    id: "",
                    memberId: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[Create.EVENT_JOINED_MEMBER]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<EventMemberCreateDeleteInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<EventMemberCreateDeleteInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "memberId",
                        type: "text",
                        getter: (thiz: RequestModule<EventMemberCreateDeleteInput>) => thiz.state.memberId,
                        setter: (v: any, thiz: RequestModule<EventMemberCreateDeleteInput>) => thiz.setState({ memberId: v })
                    }
                ]}
                submit={(state) => eventCreateJoinedMember(state)}
            />

            <RequestModule<EventMemberCreateDeleteInput>
                user={this.props.user}
                title="Associate Organizer with Event"
                path="/event/create/organizer"
                requestMethod={RequestMethod.POST}
                state={{
                    id: "",
                    memberId: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[Create.EVENT_ORGANIZER]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<EventMemberCreateDeleteInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<EventMemberCreateDeleteInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "memberId",
                        type: "text",
                        getter: (thiz: RequestModule<EventMemberCreateDeleteInput>) => thiz.state.memberId,
                        setter: (v: any, thiz: RequestModule<EventMemberCreateDeleteInput>) => thiz.setState({ memberId: v })
                    }
                ]}
                submit={(state) => eventCreateOrganizer(state)}
            />

            <RequestModule<EventPosterCreateInput>
                user={this.props.user}
                title="Create Event Poster"
                path="/event/create/poster"
                requestMethod={RequestMethod.POST}
                state={{
                    id: "",
                    title: "",
                    posterUrl: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[Create.EVENT_POSTER]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<EventPosterCreateInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<EventPosterCreateInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "title",
                        type: "text",
                        getter: (thiz: RequestModule<EventPosterCreateInput>) => thiz.state.title,
                        setter: (v: any, thiz: RequestModule<EventPosterCreateInput>) => thiz.setState({ title: v })
                    },
                    {
                        key: "posterUrl",
                        type: "text",
                        getter: (thiz: RequestModule<EventPosterCreateInput>) => thiz.state.posterUrl,
                        setter: (v: any, thiz: RequestModule<EventPosterCreateInput>) => thiz.setState({ posterUrl: v })
                    }
                ]}
                submit={(state) => eventCreatePoster(state)}
            />

            <RequestModule<EventTagCreateDeleteInput>
                user={this.props.user}
                title="Create Event Tag"
                path="/event/create/tag"
                requestMethod={RequestMethod.POST}
                state={{
                    id: "",
                    tag: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[Create.EVENT_TAG]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<EventTagCreateDeleteInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<EventTagCreateDeleteInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "tag",
                        type: "text",
                        getter: (thiz: RequestModule<EventTagCreateDeleteInput>) => thiz.state.tag,
                        setter: (v: any, thiz: RequestModule<EventTagCreateDeleteInput>) => thiz.setState({ tag: v })
                    }
                ]}
                submit={(state) => eventCreateTag(state)}
            />

            <RequestModule<EventTypeCreateInput>
                user={this.props.user}
                title="Create Event Type"
                path="/event/create/type"
                requestMethod={RequestMethod.POST}
                state={{
                    name: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[Create.EVENT_TYPE]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "name",
                        type: "text",
                        getter: (thiz: RequestModule<EventTypeCreateInput>) => thiz.state.name,
                        setter: (v: any, thiz: RequestModule<EventTypeCreateInput>) => thiz.setState({ name: v })
                    }
                ]}
                submit={(state) => eventCreateType(state)}
            />

            {/* Read */}
            <RequestModule<{}>
                user={this.props.user}
                title="Read all Events"
                path="/event/read"
                requestMethod={RequestMethod.GET}
                state={{}}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[]}
                submit={(state) => eventRead()}
            />

            <RequestModule<Input>
                user={this.props.user}
                title="Read Event by id"
                path="/event/read/{id}"
                requestMethod={RequestMethod.GET}
                state={{
                    id: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<Input>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<Input>) => thiz.setState({ id: v })
                    }
                ]}
                submit={(state) => eventReadById(state.id)}
            />

            <RequestModule<{}>
                user={this.props.user}
                title="Read all Event Types"
                path="/event/read/type"
                requestMethod={RequestMethod.GET}
                state={{}}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[]}
                submit={(state) => eventReadType()}
            />

            {/* Update */}
            <RequestModule<EventPosterUpdateInput>
                user={this.props.user}
                title="Update Event Poster"
                path="/event/update/poster"
                requestMethod={RequestMethod.PUT}
                state={{
                    eventPosterId: "",
                    id: "",
                    title: "",
                    posterUrl: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[Update.EVENT_POSTER]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "eventPosterId",
                        type: "text",
                        getter: (thiz: RequestModule<EventPosterUpdateInput>) => thiz.state.eventPosterId,
                        setter: (v: any, thiz: RequestModule<EventPosterUpdateInput>) => thiz.setState({ eventPosterId: v })
                    },
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<EventPosterUpdateInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<EventPosterUpdateInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "title",
                        type: "text",
                        getter: (thiz: RequestModule<EventPosterUpdateInput>) => thiz.state.title,
                        setter: (v: any, thiz: RequestModule<EventPosterUpdateInput>) => thiz.setState({ title: v })
                    },
                    {
                        key: "posterUrl",
                        type: "text",
                        getter: (thiz: RequestModule<EventPosterUpdateInput>) => thiz.state.posterUrl,
                        setter: (v: any, thiz: RequestModule<EventPosterUpdateInput>) => thiz.setState({ posterUrl: v })
                    }
                ]}
                submit={(state) => eventUpdatePoster(state)}
            />

            <RequestModule<EventTypeUpdateInput>
                user={this.props.user}
                title="Update Event Type"
                path="/event/update/type"
                requestMethod={RequestMethod.PUT}
                state={{
                    eventTypeId: "",
                    name: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[Update.EVENT_TYPE]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "eventTypeId",
                        type: "text",
                        getter: (thiz: RequestModule<EventTypeUpdateInput>) => thiz.state.eventTypeId,
                        setter: (v: any, thiz: RequestModule<EventTypeUpdateInput>) => thiz.setState({ eventTypeId: v })
                    },
                    {
                        key: "name",
                        type: "text",
                        getter: (thiz: RequestModule<EventTypeUpdateInput>) => thiz.state.name,
                        setter: (v: any, thiz: RequestModule<EventTypeUpdateInput>) => thiz.setState({ name: v })
                    }
                ]}
                submit={(state) => eventUpdateType(state)}
            />

            {/* Delete */}
            <RequestModule<Input>
                user={this.props.user}
                title="Delete Event"
                path="/event/delete"
                requestMethod={RequestMethod.DELETE}
                state={{
                    id: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[Delete.EVENT]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<Input>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<Input>) => thiz.setState({ id: v })
                    }
                ]}
                submit={(state) => eventDelete(state.id)}
            />

            <RequestModule<EventCollaboratorCreateDeleteInput>
                user={this.props.user}
                title="Delete Collaborator association with Event"
                path="/event/delete/collaborator"
                requestMethod={RequestMethod.DELETE}
                state={{
                    id: "",
                    collaboratorId: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[Delete.EVENT_COLLABORATOR]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<EventCollaboratorCreateDeleteInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<EventCollaboratorCreateDeleteInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "collaboratorId",
                        type: "text",
                        getter: (thiz: RequestModule<EventCollaboratorCreateDeleteInput>) => thiz.state.collaboratorId,
                        setter: (v: any, thiz: RequestModule<EventCollaboratorCreateDeleteInput>) => thiz.setState({ collaboratorId: v })
                    }
                ]}
                submit={(state) => eventDeleteCollaborator(state)}
            />

            <RequestModule<EventCommitteeCreateDeleteInput>
                user={this.props.user}
                title="Delete Committee association with Event"
                path="/event/delete/committee"
                requestMethod={RequestMethod.DELETE}
                state={{
                    id: "",
                    committeeId: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[Delete.EVENT_COMMITTEE]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<EventCommitteeCreateDeleteInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<EventCommitteeCreateDeleteInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "committeeId",
                        type: "text",
                        getter: (thiz: RequestModule<EventCommitteeCreateDeleteInput>) => thiz.state.committeeId,
                        setter: (v: any, thiz: RequestModule<EventCommitteeCreateDeleteInput>) => thiz.setState({ committeeId: v })
                    }
                ]}
                submit={(state) => eventDeleteCommittee(state)}
            />

            <RequestModule<EventMemberCreateDeleteInput>
                user={this.props.user}
                title="Remove Member of joined Event"
                path="/event/delete/joinedMember"
                requestMethod={RequestMethod.DELETE}
                state={{
                    id: "",
                    memberId: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[Delete.EVENT_JOINED_MEMBER]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<EventMemberCreateDeleteInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<EventMemberCreateDeleteInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "memberId",
                        type: "text",
                        getter: (thiz: RequestModule<EventMemberCreateDeleteInput>) => thiz.state.memberId,
                        setter: (v: any, thiz: RequestModule<EventMemberCreateDeleteInput>) => thiz.setState({ memberId: v })
                    }
                ]}
                submit={(state) => eventDeleteJoinedMember(state)}
            />

            <RequestModule<EventMemberCreateDeleteInput>
                user={this.props.user}
                title="Delete Organizer association with Event"
                path="/event/delete/organizer"
                requestMethod={RequestMethod.DELETE}
                state={{
                    id: "",
                    memberId: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[Delete.EVENT_ORGANIZER]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<EventMemberCreateDeleteInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<EventMemberCreateDeleteInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "memberId",
                        type: "text",
                        getter: (thiz: RequestModule<EventMemberCreateDeleteInput>) => thiz.state.memberId,
                        setter: (v: any, thiz: RequestModule<EventMemberCreateDeleteInput>) => thiz.setState({ memberId: v })
                    }
                ]}
                submit={(state) => eventDeleteOrganizer(state)}
            />

            <RequestModule<Input>
                user={this.props.user}
                title="Delete Event Poster by poster id"
                path="/event/delete/poster"
                requestMethod={RequestMethod.DELETE}
                state={{
                    id: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[Delete.EVENT_POSTER]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<Input>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<Input>) => thiz.setState({ id: v })
                    }
                ]}
                submit={(state) => eventDeletePoster(state.id)}
            />

            <RequestModule<EventTagCreateDeleteInput>
                user={this.props.user}
                title="Delete Event Tag"
                path="/event/delete/poster"
                requestMethod={RequestMethod.DELETE}
                state={{
                    id: "",
                    tag: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[Delete.EVENT_TAG]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<EventTagCreateDeleteInput>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<EventTagCreateDeleteInput>) => thiz.setState({ id: v })
                    },
                    {
                        key: "tag",
                        type: "text",
                        getter: (thiz: RequestModule<EventTagCreateDeleteInput>) => thiz.state.tag,
                        setter: (v: any, thiz: RequestModule<EventTagCreateDeleteInput>) => thiz.setState({ tag: v })
                    }
                ]}
                submit={(state) => eventDeleteTag(state)}
            />

            <RequestModule<Input>
                user={this.props.user}
                title="Delete Event Type by id"
                path="/event/delete/type"
                requestMethod={RequestMethod.DELETE}
                state={{
                    id: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[Delete.EVENT_TYPE]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<Input>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<Input>) => thiz.setState({ id: v })
                    }
                ]}
                submit={(state) => eventDeleteType(state.id)}
            />
        </div>
    }
}

export interface Input {
    id: string
}

export interface EventCreateInput {
    title: string
    subTitle: string
    shortDescription: string
    longDescription: string
    imgUrl: string
    content: string
    eventTypeId: string
    subject: string

    streetName: string
    houseNumber: number
    houseNumberLetter: string | null
    houseNumberAddition: string | null
    postalCode: string
    city: string

    addressRoom: string | null
    schoolYear: number

    startDateYear: number
    startDateMonth: number
    startDateDay: number

    endDateYear: number | null
    endDateMonth: number | null
    endDateDay: number | null

    startTimeHours: number
    startTimeMinutes: number
    startTimeSeconds: number

    endTimeHours: number
    endTimeMinutes: number
    endTimeSeconds: number

    price: number
    link: string | null
}