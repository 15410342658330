import React from 'react'
import { UserLoggedIn, CommitteeCreateInput } from '../../ApiModels'

import '../Admin.css'
import RequestModule from '../RequestModule';
import { committeeCreate, committeeRead, committeeReadById } from '../../Requests';
import { Create, RequestMethod } from '../../Global';

interface CommitteeProps {
    user: UserLoggedIn
}

interface CommitteeState {
}

export default class Committee extends React.Component<CommitteeProps, CommitteeState> {
    constructor(props: CommitteeProps) {
        super(props)
        this.state = {
        }
    }

    async componentDidMount() {

    }

    public render() {
        return <div>
            {/* Create */}
            <RequestModule<CommitteeCreateInput>
                user={this.props.user}
                title="Create Committee"
                path="/committee/create"
                requestMethod={RequestMethod.POST}
                state={{
                    shortName: "",
                    longName: "",
                    shortDescription: "",
                    longDescription: "",
                    imgUrl: "",
                    mail: "",
                    isActive: true
                }}
                permissionsInfo={[]}
                permissionsCreate={[Create.COMMITTEE]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "shortName",
                        type: "text",
                        getter: (thiz: RequestModule<CommitteeCreateInput>) => thiz.state.shortName,
                        setter: (v: any, thiz: RequestModule<CommitteeCreateInput>) => thiz.setState({ shortName: v })
                    },
                    {
                        key: "longName",
                        type: "text",
                        getter: (thiz: RequestModule<CommitteeCreateInput>) => thiz.state.longName,
                        setter: (v: any, thiz: RequestModule<CommitteeCreateInput>) => thiz.setState({ longName: v })
                    },
                    {
                        key: "shortDescription",
                        type: "text",
                        getter: (thiz: RequestModule<CommitteeCreateInput>) => thiz.state.shortDescription,
                        setter: (v: any, thiz: RequestModule<CommitteeCreateInput>) => thiz.setState({ shortDescription: v })
                    },
                    {
                        key: "longDescription",
                        type: "text",
                        getter: (thiz: RequestModule<CommitteeCreateInput>) => thiz.state.longDescription,
                        setter: (v: any, thiz: RequestModule<CommitteeCreateInput>) => thiz.setState({ longDescription: v })
                    },
                    {
                        key: "imgUrl",
                        type: "text",
                        getter: (thiz: RequestModule<CommitteeCreateInput>) => thiz.state.imgUrl,
                        setter: (v: any, thiz: RequestModule<CommitteeCreateInput>) => thiz.setState({ imgUrl: v })
                    },
                    {
                        key: "mail",
                        type: "text",
                        getter: (thiz: RequestModule<CommitteeCreateInput>) => thiz.state.mail,
                        setter: (v: any, thiz: RequestModule<CommitteeCreateInput>) => thiz.setState({ mail: v })
                    }
                ]}
                submit={(state) => committeeCreate(state)}
            />

            {/* Read */}
            <RequestModule<{}>
                user={this.props.user}
                title="Read all Committees"
                path="/committee/read"
                requestMethod={RequestMethod.GET}
                state={{}}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[]}
                submit={(state) => committeeRead()}
            />

            <RequestModule<Input>
                user={this.props.user}
                title="Read Committee by id"
                path="/committee/read/{id}"
                requestMethod={RequestMethod.GET}
                state={{
                    id: ""
                }}
                permissionsInfo={[]}
                permissionsCreate={[]}
                permissionsRead={[]}
                permissionsUpdate={[]}
                permissionsDelete={[]}
                items={[
                    {
                        key: "id",
                        type: "text",
                        getter: (thiz: RequestModule<Input>) => thiz.state.id,
                        setter: (v: any, thiz: RequestModule<Input>) => thiz.setState({ id: v })
                    }
                ]}
                submit={(state) => committeeReadById(state.id)}
            />
        </div>
    }
}

interface Input {
    id: string
}