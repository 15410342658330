import React, {ReactElement} from "react";
import './Home.css';
import '../Form.css'
import {EventOverview,} from "../ApiModels";

type EventGridProps = {
    setter: (event: EventOverview) => void
    events: Array<EventOverview> | null
}

export default class EventGrid extends React.Component<EventGridProps, {}> {

    createEventBox(event: EventOverview) {
        return (
            <div className='event-list-item'>
                <div className='home-event-box' onClick={
                    () => this.props.setter(event)
                }>
                    <div className='home-event-image-container'>
                        <img src={event.imgUrl} alt=''/>
                    </div>
                    <div className='home-event-data-container'>
                        <h3>{event.title}</h3>
                        <h5>{event.subTitle}</h5>
                        <h5>{event.subject}</h5>
                        <h5>{event.shortDescription}</h5>
                    </div>
                    <div className='btn-more'>
                        <div>Read More...</div>
                        <div><i className="fas fa-arrow-right"></i></div>
                    </div>
                </div>
            </div>)
    }

    createGrid(events: Array<EventOverview>) {
        var grid: ReactElement = <div className='event-list-container'></div>
        var divArr: ReactElement[] = [];
        for (var i = 0; i < events.length; i++) {
            divArr.push(this.createEventBox(events[i]))
            if (i < events.length - 1) {
                divArr.push(<hr/>);
            }
        }
        grid = React.cloneElement(grid, {children: divArr});
        return grid;
    }

    render() {
        if (this.props.events != null) {
            return (
                <div>
                    {this.createGrid(this.props.events)}
                </div>
            )
        } else {
            return (undefined)
        }
    }
}